import { Link } from "react-router-dom"

import { Button } from "@/components/ui/button"
import { Input, ToggleableInput } from "@/components/ui/input"

import LoginIllustration from "@/assets/images/LoginIllustration.svg?react"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import { loginThunk } from "@/redux/auth"
import { Navigate } from "react-router"
import { toast } from "sonner"

const loginSchema = z.object({
  email: z.string().min(1, { message: "Email is required" }),
  password: z.string().min(1, { message: "Password is required" }),
})

function LoginForm() {
  const dispatch = useAppDispatch()
  const form = useForm<z.infer<typeof loginSchema>>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  })
  const onSubmit = async (values: z.infer<typeof loginSchema>) => {
    const loadingToastId = toast.loading("Logging in...")
    const params = { email: values.email, password: values.password }
    const result = await dispatch(loginThunk(params))

    if (loginThunk.fulfilled.match(result)) {
      toast.success("Logged in successfully!", {
        description: "You will be redirected shortly.",
        id: loadingToastId,
      })
    } else if (loginThunk.rejected.match(result)) {
      toast.error("Log in failed.", {
        description: (result.payload! as { detail: string }).detail,
        id: loadingToastId,
      })
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid gap-4">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    placeholder="m@example.com"
                    type="email"
                    autoComplete="email"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <div className="flex items-center">
                  <FormLabel>Password</FormLabel>
                  <Link
                    to="/forgot-password"
                    className="ml-auto inline-block text-sm underline"
                  >
                    Forgot your password?
                  </Link>
                </div>
                <FormControl>
                  <ToggleableInput
                    required
                    autoComplete="current-password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" className="w-full">
            Login
          </Button>
          <Button disabled variant="outline" className="w-full">
            Login with Google
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default function LoginPage() {
  const accessToken = useAppSelector((state) => state.auth.accessToken)

  if (accessToken !== null) {
    return <Navigate to="/" />
  }

  return (
    <div className="w-full lg:grid lg:min-h-screen lg:grid-cols-2">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-5xl font-300 font-outfit">Log in</h1>
            <div className="pb-5" />
            {/*<p className="text-balance text-muted-foreground">*/}
            {/*  Enter your email below to login to your account*/}
            {/*</p>*/}
          </div>
          <LoginForm />
          <div className="mt-4 text-center text-sm">
            Don&apos;t have an account?{" "}
            <Link href="#" className="underline">
              Sign up
            </Link>
          </div>
        </div>
      </div>
      <div className="hidden bg-muted lg:block lg:h-full p-4">
        <div className="flex flex-col h-full p-10 bg-bgSecondary rounded-3xl">
          <LoginIllustration className="p-10 flex-grow-2 mx-auto" />
          {/* TODO: here it should be in the middle of remaining height */}
          <div className="flex-grow pl-10 flex-col justify-center">
            <h3 className="font-400 text-25 font-outfit">
              Welcome to Pactus <span className="text-blue-200">AI</span>!
            </h3>
            <p className="pt-2 text-text-neutral">
              Please log in with your company e-mail address to get started.
            </p>
          </div>
        </div>
        {/*<img*/}
        {/*  src="/placeholder.svg"*/}
        {/*  alt="Image"*/}
        {/*  width="1920"*/}
        {/*  height="1080"*/}
        {/*  className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"*/}
        {/*/>*/}
      </div>
    </div>
  )
}
