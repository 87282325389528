import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Icon } from "@/assets"
import { ReloadIcon } from "@radix-ui/react-icons"
import { DocumentSchema, useRenameDocumentMutation } from "@/api/resources"
import { toast } from "sonner"

const createDocumentSchema = z.object({
  display_name: z.string().min(2).max(255),
})

export interface Params {
  document: DocumentSchema
  open: boolean
  onOpenChange: (open: boolean) => void
  showButton?: boolean
}

const renderForm = ({ field }) => {
  return (
    <FormItem>
      <FormLabel>Document name</FormLabel>
      <FormControl>
        <Input
          type="text"
          placeholder="Enter name of the document"
          {...field}
        />
      </FormControl>
      <FormDescription>
        The document name should have between 2 and 255 characters.
      </FormDescription>
    </FormItem>
  )
}

export const RenameDocumentDialog = ({
  document,
  open,
  onOpenChange,
  showButton,
}: Params) => {
  const [renameDocument, { isLoading }] = useRenameDocumentMutation()

  const form = useForm<z.infer<typeof createDocumentSchema>>({
    resolver: zodResolver(createDocumentSchema),
    values: {
      display_name: document.display_name,
    },
  })

  async function onSubmit(values: z.infer<typeof createDocumentSchema>) {
    const loadingId = toast.loading("Renaming document...")

    const result = await renameDocument({
      document_id: document.document_id,
      display_name: values.display_name,
    })

    if (result.error && (result.error as any).data) {
      toast.error((result.error as any).data.detail, {
        id: loadingId,
      })
    } else {
      toast.success("Document renamed successfully.", {
        id: loadingId,
      })

      onOpenChange(false)
    }
  }

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        {showButton && (
          <DialogTrigger asChild>
            <Button
              variant="ghost"
              className="flex items-center text-blue-200 font-500 text-16 gap-1.5"
            >
              <Icon name="Plus" />{" "}
              <span className="text-blue-200 text-15">Create Folder</span>
            </Button>
          </DialogTrigger>
        )}
        <DialogContent>
          <DialogHeader>
            <h3 className="text-22 text-black text-center">Rename Document</h3>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="display_name"
                render={renderForm}
              />
              <Button
                type="reset"
                variant="secondary"
                onClick={() => {
                  form.reset()
                  onOpenChange(false)
                }}
              >
                Cancel
              </Button>
              <Button className="ml-2" type="submit">
                {isLoading ? (
                  <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  ""
                )}
                Rename
              </Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  )
}
