export const htmlFile = `
<html>
    <head>
        <style type="text/css">
            .awlist1 {
                list-style: none;
                counter-reset: awlistcounter43_0;
            }
            .awlist1 > li:before {
                content: counter(awlistcounter43_0) ")";
                counter-increment: awlistcounter43_0;
            }
            .awlist2 {
                list-style: none;
                counter-reset: awlistcounter43_1;
            }
            .awlist2 > li:before {
                content: counter(awlistcounter43_1, lower-latin) ")";
                counter-increment: awlistcounter43_1;
            }
            .awlist3 {
                list-style: none;
                counter-reset: awlistcounter36_0;
            }
            .awlist3 > li:before {
                content: counter(awlistcounter36_0, lower-latin) ")";
                counter-increment: awlistcounter36_0;
            }
            .awlist4 {
                list-style: none;
                counter-reset: awlistcounter37_0;
            }
            .awlist4 > li:before {
                content: counter(awlistcounter37_0) ")";
                counter-increment: awlistcounter37_0;
            }
            .awlist5 {
                list-style: none;
                counter-reset: awlistcounter42_0;
            }
            .awlist5 > li:before {
                content: counter(awlistcounter42_0) ")";
                counter-increment: awlistcounter42_0;
            }
            .awlist6 {
                list-style: none;
                counter-reset: awlistcounter38_0;
            }
            .awlist6 > li:before {
                content: counter(awlistcounter38_0) ")";
                counter-increment: awlistcounter38_0;
            }
            .awlist7 {
                list-style: none;
                counter-reset: awlistcounter39_0;
            }
            .awlist7 > li:before {
                content: counter(awlistcounter39_0) ")";
                counter-increment: awlistcounter39_0;
            }
            .awlist8 {
                list-style: none;
                counter-reset: awlistcounter40_0;
            }
            .awlist8 > li:before {
                content: counter(awlistcounter40_0) ")";
                counter-increment: awlistcounter40_0;
            }
            .awlist9 {
                list-style: none;
                counter-reset: awlistcounter41_0;
            }
            .awlist9 > li:before {
                content: counter(awlistcounter41_0) ")";
                counter-increment: awlistcounter41_0;
            }
        </style>
    </head>
    <body style="line-height: 115%; font-family: Calibri; font-size: 11pt;">
        <div>
            <div style="-aw-headerfooter-type: header-primary; clear: both;">
                <h2 style="margin-top: 2pt; margin-bottom: 0pt; text-align: center; page-break-inside: avoid; page-break-after: avoid; line-height: 107%; font-size: 13pt;">
                    <span style="height: 0pt; margin-top: -2pt; text-align: left; display: block; position: absolute; z-index: -65538;">
                    </span>
                    <span style="-aw-sdt-tag: '';">
                    </span>
                    <span style="font-family: 'Bahnschrift SemiLight SemiConde'; font-weight: normal; color: #365f91;">Střední průmyslová škola strojírenská a Jazyková škola s</span>
                    <span style="font-family: 'Bahnschrift SemiLight SemiConde'; font-weight: normal; color: #365f91;">&#xa0;</span>
                    <span style="font-family: 'Bahnschrift SemiLight SemiConde'; font-weight: normal; color: #365f91;">právem státní jazykové zkoušky, Kolín IV, Heverova 191</span>
                </h2>
                <p style="margin-top: 0pt; margin-bottom: 0pt; line-height: normal; font-size: 10pt;"><span style="font-family: 'Times New Roman'; -aw-import: ignore;">&#xa0;</span></p>
            </div>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <h1 style="margin-top: 12pt; margin-bottom: 0pt; text-align: center; page-break-inside: avoid; page-break-after: avoid; line-height: normal; font-size: 16pt;">
                <span style="font-family: 'Bahnschrift SemiLight SemiConde'; font-weight: normal; color: #365f91;">Nájemní smlouva</span>
            </h1>
            <p style="margin-top: 0pt; margin-bottom: 10pt; text-align: center; line-height: normal;"><span style="font-weight: bold; -aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt; text-align: center; line-height: normal; font-size: 14pt;">
                <span style="font-size: 11pt;">Číslo smlouvy příspěvkové organizace: </span><span style="font-weight: bold;">5/48665860/2023</span>
            </p>
            <p style="margin-top: 0pt; margin-bottom: 10pt; text-align: center; line-height: normal;"><span style="font-weight: bold; -aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt; text-align: center; line-height: normal;"><span style="font-weight: bold;">Smluvní strany</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt; text-align: justify; line-height: normal;"><span style="font-weight: bold; -aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;">
                <span style="font-weight: bold;">Střední průmyslová škola strojírenská a Jazyková škola s</span><span style="font-weight: bold;">&#xa0;</span>
                <span style="font-weight: bold;">právem státní jazykové zkoušky, Kolín IV, Heverova 191</span>
            </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span>se sídlem: Kolín IV, Heverova 191</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span>Zastoupená: Ing. Františkem Pražákem, Ph.D., ředitelem příspěvkové organizace</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span>IČ 48665860</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span>Bankovní spojení: KB Kolín, č.ú.</span><span style="-aw-import: spaces;">&#xa0; </span><span>9276170247/0100</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span>(dále jen „</span><span style="font-weight: bold;">pronajímatel</span><span>“)</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 6pt; text-align: justify; line-height: normal;"><span>a</span></p>
            <p style="margin-top: 0pt; margin-bottom: 6pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="font-weight: bold;">Ing. Dušan Zahrádka </span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span>narozený ……………………..</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span>bytem Kolín IV, Komenského 375</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span>na straně druhé jakožto „</span><span style="font-weight: bold;">nájemce</span><span>“</span></p>
            <p style="margin-top: 0pt; margin-bottom: 6pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt; text-align: center; line-height: normal;">
                <span>uzavírají dle ustanovení § 2201 a následujících zákona č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů tuto </span>
            </p>
            <p style="margin-top: 0pt; margin-bottom: 10pt; text-align: center; line-height: normal;"><span style="font-weight: bold;">nájemní smlouvu</span><span>:</span></p>
            <p style="margin-top: 0pt; margin-bottom: 6pt; text-align: center; line-height: normal;"><span>I.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt; text-align: justify; line-height: normal;">
                <span>Pronajímatel je pověřený zřizovatelem hospodařením s budovou čp. 375 na pozemku p.č. st. 2465/1, zapsané v</span><span>&#xa0;</span>
                <span>katastru nemovitostí vedeném Katastrálním úřadem pro Středočeský kraj, Katastrální pracoviště Kolín, pro obec a k.ú. Kolín na listu vlastnickém 5389 pro obec 533165 Kolín a katastrální území 668150 Kolín.</span>
            </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>II.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>Předmět nájmu</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;">
                <span>Pronajímatel pronajímá a podpisem této smlouvy nájemní již pronajal, majetek zřizovatele svěřený příspěvkové organizaci k</span><span>&#xa0;</span>
                <span>hospodaření, panu Ing. Dušanovi Zahrádkovi (dále jen „nájemník“) byt v</span><span>&#xa0;</span><span>přízemí výše uvedené budovy čp. 375 v</span><span>&#xa0;</span><span>Komenského ulici v</span><span>&#xa0;</span>
                <span>Kolíně IV o celkové výměře 56,51 m</span><span style="font-size: 7.33pt; vertical-align: super;">2</span>
                <span>
                    ,(chodba, koupelna, WC, kuchyň, pokoj a ložnice) včetně veškerého příslušenství bytu a to na dobu určitou od 1. ledna 2023 do 31. prosince 2023. Současně je nájemník oprávněn užívat společné prostory budovy čp. 375
                    (chodba a schodiště). Uvedené pronajaté prostory mají právo užívat i členové domácnosti nájemníka, to jest paní Jana Chvalovská, narozená …………. a pan Jiří Zahrádka, narozený …………………………..
                </span>
            </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="color: #00b0f0; -aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="color: #00b0f0; -aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>III.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>Doba trvání nájmu</span></p>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: justify; line-height: normal;">
                <span>Nájem se sjednává na dobu určitou od 1. ledna 2023 do 31. prosince 2023. Nájem se neprodlužuje bez výslovné písemné dohody obou účastníků, a to i v</span><span>&#xa0;</span>
                <span>případě, pokud nájemník bude pokračovat v</span><span>&#xa0;</span><span>užívání bytu po skončení dohodnuté doby nájmu. Po uplynutí dohodnuté doby nájmu nájemník může požádat prodloužení nájmu na další období. </span>
            </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>IV.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>Úhrada za nájem</span></p>
            <ol type="1" class="awlist1" style="margin: 0pt; padding-left: 0pt;">
                <li style="margin-left: 18pt; text-indent: -17.85pt; text-align: justify; line-height: normal; -aw-list-padding-sml: 8.07pt;">
                    <span style="width: 8.07pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>Nájemník se zavazuje platit pronajímateli </span>
                    <span style="font-weight: bold;">úhradu za nájem částku 8031 Kč měsíčně</span><span>. Výše nájemného byla stanovena takto:</span>
                    <ol type="a" class="awlist2" style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt;">
                        <li style="margin-left: 18pt; text-indent: -18pt; -aw-list-padding-sml: 8.22pt;">
                            <span style="width: 8.22pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                            <span>výše měsíčního nájemného za výměru bytu je 5651 Kč (100,00 Kč x 56,51 m</span><span style="font-size: 7.33pt; vertical-align: super;">2</span><span>);</span>
                        </li>
                        <li style="margin-left: 18pt; text-indent: -18pt; -aw-list-padding-sml: 8.22pt;">
                            <span style="width: 8.22pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                            <span>výše měsíční úhrady ceny za otop bytu, vodné stočné, odvoz komunálního odpadu a náklady spojené s</span><span>&#xa0;</span><span>provozem společných prostor je 2380 Kč, z</span><span>&#xa0;</span>
                            <span>toho otop bytu 29,02 Kč/m</span><span style="font-size: 7.33pt; vertical-align: super;">2</span><span> = 1639,92 Kč = zaokrouhleně 1640 Kč, teplá a studená voda 540 Kč, náklady spojené s</span>
                            <span>&#xa0;</span><span>provozem společných prostor 200 Kč.</span>
                        </li>
                    </ol>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; -aw-list-padding-sml: 8.22pt;">
                    <span style="width: 8.22pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-weight: bold;">Úhrada za nájem je splatná vždy do 5. dne měsíce následujícího na účet pronajímatele číslo 9276170247/0100 u KB Kolín </span>
                    <span>(například úhrada za nájem za měsíc leden je splatná do 5. února). První splátku je nájemce povinen uhradit pronajímateli na výše uvedený účet do čtrnácti dnů od data podpisu smlouvy.</span>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; -aw-list-padding-sml: 8.22pt;">
                    <span style="width: 8.22pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span>Vedle úhrady za nájem se nájemník zavazuje platit úhradu za veškeré odebrané energie dle stavu, zjištěného v</span><span>&#xa0;</span><span>samostatných měřících místech, a za služby spojené s</span>
                    <span>&#xa0;</span><span>užíváním bytu, jednotlivým dodavatelům těchto energií a služeb. Zavazuje se takto hradit zejména úhradu za:</span>
                </li>
            </ol>
            <ol type="a" class="awlist3" style="margin: 0pt; padding-left: 0pt;">
                <li style="margin-left: 36pt; text-indent: -18pt; text-align: justify; line-height: normal; -aw-list-padding-sml: 8.22pt;">
                    <span style="width: 8.22pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>elektřinu</span>
                </li>
                <li style="margin-left: 36pt; text-indent: -18pt; text-align: justify; line-height: normal; -aw-list-padding-sml: 8.22pt;">
                    <span style="width: 8.22pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>telefon a internet</span>
                </li>
                <li style="margin-left: 36pt; text-indent: -18pt; text-align: justify; line-height: normal; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>rozhlasové a televizní poplatky</span>
                </li>
                <li style="margin-left: 36pt; text-indent: -18pt; text-align: justify; line-height: normal; -aw-list-padding-sml: 8.22pt;">
                    <span style="width: 8.22pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>eventuální další služby,</span>
                </li>
            </ol>
            <p style="margin-top: 0pt; margin-left: 18pt; margin-bottom: 0pt; text-align: justify; line-height: normal;">
                <span>a to takto: elektrickou energii, rozhlasové a televizní poplatky, internet a telefon přímo jednotlivým dodavatelům.</span>
            </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>V.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>Povinnosti nájemníka</span></p>
            <ol type="1" class="awlist4" style="margin: 0pt; padding-left: 0pt;">
                <li style="margin-top: 6pt; margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Nájemník je povinen zabezpečovat úklid a čistotu vnějšího přístupu do předmětu nájmu.</span>
                </li>
                <li style="margin-top: 6pt; margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Nájemník se zavazuje užívat předmět nájmu jako řádný hospodář, dbát na čistotu a hygienu jak v</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">předmětu nájmu, tak i v přístupových prostorách k</span><span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">němu, zajistit v</span>
                    <span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">předmětu nájmu dodržování předpisů z</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">oblasti požární ochrany.</span>
                </li>
                <li style="margin-top: 6pt; margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Nájemník se zavazuje hradit náklady spojené s</span><span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">obvyklou údržbou a provozem bytu.</span>
                </li>
                <li style="margin-top: 6pt; margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Nájemník je povinen oznámit bez zbytečného odkladu pronajímateli veškeré změny, které nastaly ohledně předmětu nájmu.</span>
                </li>
                <li style="margin-top: 6pt; margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Nájemník je povinen uhradit pronajímateli veškeré případné škody vzniklé na předmětu nájmu v</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">důsledku činnosti nájemníka, pokud je neodstraní uvedením předmětu nájmu do původního stavu.</span>
                </li>
                <li style="margin-top: 6pt; margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Jakékoliv stavební změny, úpravy nebo opravy na pronajatém bytě je nájemník oprávněn činit pouze s</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">předchozím písemným souhlasem pronajímatele, přičemž musí vždy být písemně dohodnut rozsah úprav, změn nebo oprav a způsob jejich financování.</span>
                </li>
            </ol>
            <p style="margin-top: 6pt; margin-left: 18pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 6pt; margin-bottom: 0pt; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>VI.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>Práva nájemníka</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;">
                <span>Nájemník je oprávněn užívat byt v</span><span>&#xa0;</span><span>rozsahu dohodnutém v</span><span>&#xa0;</span><span>této smlouvě. </span>
            </p>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="font-weight: bold; -aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="font-weight: bold; -aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>VII.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>Povinnosti pronajímatele</span></p>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: justify; line-height: normal;">
                <span style="-aw-import: spaces;">&#xa0;</span>
                <span>
                    Pronajímatel je povinen udržovat najatý byt ve stavu způsobilém k užívání tak, jak byl nájemníku podle této smlouvy předán. Jakékoliv stavební změny, úpravy nebo opravy na pronajatém bytě je pronajímatel oprávněn činit
                    pouze s písemným předchozím souhlasem nájemník, přičemž musí vždy být písemně dohodnut rozsah úprav, změn nebo oprav a způsob jejich financování.
                </span>
            </p>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="width: 35.4pt; display: inline-block;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-left: 177pt; margin-bottom: 0pt; text-indent: 35.4pt; text-align: justify; line-height: normal;"><span>VIII.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;">
                <span style="-aw-import: spaces;">
                    &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span>
                <span style="-aw-import: spaces;">&#xa0;</span><span style="-aw-import: spaces;">&#xa0;&#xa0; </span><span>Pojištění bytu</span>
            </p>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: justify; line-height: normal;">
                <span>Náklady na pojištění budovy hradí pronajímatel. Nájemník se zavazuje sjednat pojištění odpovědnosti za škody způsobené užíváním najatého bytu. </span>
            </p>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="font-weight: bold; -aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>IX.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>Způsob užívání bytu</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <ol type="1" class="awlist5" style="margin: 0pt; padding-left: 0pt;">
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Vztahy mezi pronajímatelem a nájemníkem se řídí touto smlouvou. Ve věcech touto smlouvou výslovně neupravených platí ustanovení § 2201 a násl. občanského zákoníku.</span>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family: Calibri;">Spolu s</span>
                    <span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">nájemníkem jsou oprávněni na základě této smlouvy užívat najatý byt příslušníci nájemníkovi domácnosti, kteří jsou uvedeni v</span>
                    <span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">čl. II. této smlouvy.</span>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Nájemník je povinen ohlásit pronajímateli neprodleně veškeré změny v</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">počtu osob, které jsou oprávněny spolu s</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">ním najatý byt užívat, nejpozději do 7 dnů ode dne skutečnosti, která zakládá změnu v</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">počtu příslušníků domácnosti. Odvozené právo každého příslušníka domácnosti nájemníka, který není uveden v</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">předávacím protokole, užívat spolu s</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">nájemníkem pronajatý byt, vzniká dnem, kdy nájemník tuto změnu písemně ohlásí pronajímateli.</span>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Nedodržení povinnosti nájemníka uvedené v</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">odst. 3 tohoto článku této smlouvy je podstatným porušením povinností nájemníka. </span>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Právo příslušníků domácnosti nájemníka užívat spolu s</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">
                        nájemníkem najatý byt je právem odvozeným od práva nájemníka a zaniká vždy nejpozději spolu se zánikem nájmu. Příslušník domácnosti nájemníka není oprávněn užívat najatý byt po skončení nájmu
                    </span>
                    <span style="font-family: Calibri; font-weight: bold;">.</span>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family: Calibri;">K</span>
                    <span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">
                        najatému bytu nevzniká manželovi nájemníka společné právo nájmu manželů. K najatému bytu nevzniká ani žádné jiné právo společného nájmu. Nájem je výlučným samostatným právem nájemníka.
                    </span>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family: Calibri;">Změnou v</span>
                    <span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">osobě pronajímatele nájem nezaniká. Nový pronajímatel vstupuje do právního postavení pronajímatele.</span>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family: Calibri;">Nájem nepřechází na třetí osoby v</span>
                    <span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">žádném z</span><span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">případů uvedených v</span>
                    <span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">ustanoveních občanského zákoníku, tj. smrtí nájemníka či opuštěním společné domácnosti nájemníkem. </span>
                </li>
            </ol>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="font-weight: bold; -aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>X.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>Skončení nájmu</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span>Sjednaný nájem bytu zaniká:</span></p>
            <ol type="1" class="awlist6" style="margin: 0pt; padding-left: 0pt;">
                <li style="margin-left: 36pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family: Calibri;">smrtí nájemníka,</span>
                </li>
                <li style="margin-left: 36pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family: Calibri;">dohodou,</span>
                </li>
                <li style="margin-left: 36pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family: Calibri;">výpovědí některého z</span>
                    <span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">účastníků této smlouvy,</span>
                </li>
                <li style="margin-left: 36pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family: Calibri;">uplynutím dohodnuté doby.</span>
                </li>
            </ol>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>XI.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;">
                <span style="-aw-import: spaces;">
                    &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span>
                <span>Smrt nájemníka</span>
            </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;">
                <span>Dnem smrti nájemníka vztah mezi účastníky této smlouvy zaniká. Příslušníci domácnosti nájemníka, kteří s</span><span>&#xa0;</span><span>nájemníkem žili, v</span><span>&#xa0;</span>
                <span>den jeho smrti ve společné domácnosti, mají právo pokračovat v</span><span>&#xa0;</span><span>nájmu za stejných podmínek, které jsou dohodnuty v</span><span>&#xa0;</span>
                <span>této smlouvě jen se souhlasem pronajímatele.</span>
            </p>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>XII.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;">
                <span style="-aw-import: spaces;">
                    &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span>
                <span>Dohoda</span>
            </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>Dohoda o skončení nájmu musí mít písemnou formu a musí obsahovat datum, ke kterému nájem skončí. </span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>XIII.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>Výpověď</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;">
                <span>Vztah z</span><span>&#xa0;</span>
                <span>této smlouvy skončí také výpovědí kterékoliv ze smluvních stran. Výpověď musí mít písemnou formu a musí být doručena druhé straně. Výpověď nemusí obsahovat výpovědní důvod, anebo může být dána oběma stranami z</span>
                <span>&#xa0;</span><span>jakéhokoliv důvodu. Výpovědní lhůta činí pro obě strany tři měsíce, začne běžet prvým dnem měsíce následujícího po měsíci, v</span><span>&#xa0;</span>
                <span>
                    němž byla výpověď doručena druhému účastníkovi a skončí ke konci posledního kalendářního měsíce. Výpověď pronajímatele z nájmu nevyžaduje přivolení soudu a nájemník nemá právo požadovat určení neplatnosti výpovědi pro
                    neexistenci výpovědního důvodu. To neplatí pro eventuální jiné vady výpovědi.
                </span>
            </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="font-weight: bold; -aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>XIV.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>Uplynutí doby</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;">
                <span>Neskončí-li nájem žádným z</span><span>&#xa0;</span><span>důvodů, které jsou uvedeny výše, skončí nejpozději uplynutím dohodnuté doby nájmu (čl. III. této smlouvy). </span>
            </p>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>XV.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>Vyklizení bytu</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <ol type="1" class="awlist7" style="margin: 0pt; padding-left: 0pt;">
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family: Calibri;">Při skončení nájmu z</span>
                    <span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">jakéhokoliv důvodu, s</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">výjimkou smrti nájemníka, je nájemník povinen najatý byt vyklidit a vyklizený předat pronajímateli ve stavu v</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">jakém jej do nájmu převzal s</span><span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">přihlédnutím k</span>
                    <span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">obvyklému opotřebení, nejpozději v</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">den následující po dni skončení nájmu. Nájemník ani příslušnosti jeho domácnosti nemají právo na jakoukoliv bytovou náhradu. </span>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Při skončení nájmu smrtí nájemníka jsou příslušníci jeho domácnosti, kteří žili s nájemníkem v</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">době jeho smrti v</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">pronajatém bytě povinni předmětný byt vyklidit společně a nerozdílně do tří měsíců ode dne smrti nájemníka, pokud nedojde k pokračování v</span>
                    <span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">nájmu podle článku XI. této smlouvy.</span>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family: Calibri;">Nesplní-li nájemník, či v</span>
                    <span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">
                        případě jeho smrti příslušníci jeho domácnosti, povinnost k vyklizení bytu podle odst. 1 nebo 2 tohoto článku této smlouvy, je pronajímatel oprávněn zajistit vyklizení bytu sám na náklady nájemníka, či příslušníků
                        jeho domácnosti. Za této situace je oprávněn vstoupit do najatého bytu i bez souhlasu nájemníka či příslušníků jeho domácnosti, zajistit vystěhování veškerých věcí nájemníka či příslušníků jeho domácnosti a uložit je
                        na vhodném místě. O tomto úkonu je povinen pronajímatel zajistit přiměřenou dokumentaci, tj. zejména písemný seznam všech věcí nacházejících se v
                    </span>
                    <span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">bytě v</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">době úkonu, fotodokumentaci či videozáznam. Úkonu musí být přítomny nejméně další dvě fyzické osoby. V</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">
                        případě pochybností o stavu stěhovaného majetku platí seznam pořízený pronajímatelem při uvedeném úkonu. Náklady stěhování a uskladnění věcí hradí nájemník či příslušníci jeho domácnosti
                    </span>
                    <span style="font-family: Calibri; font-weight: bold;">.</span><span style="font-family: Calibri; font-weight: bold; -aw-import: spaces;">&#xa0; </span>
                </li>
            </ol>
            <p style="margin-top: 0pt; margin-left: 18pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-left: 18pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>XVI.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>Odpovědnost za škodu</span></p>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: justify; line-height: normal;">
                <span>Nájemník odpovídá pronajímateli za škody, vzniklé na majetku, který je předmětem nájmu, zaviněné nájemníkem, jakož i třetími osobami, které se souhlasem nájemce zdržovaly v pronajatém objektu.</span>
            </p>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>XVII.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>Doručování</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <ol type="1" class="awlist8" style="margin: 0pt; padding-left: 0pt;">
                <li style="margin-left: 18pt; text-indent: -17.85pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.69pt;">
                    <span style="width: 8.69pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Obě strany prohlašují, že adresami pro doručování písemností, včetně jednostranných právních úkonů jsou:</span>
                    <ol type="a" class="awlist2" style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt;">
                        <li style="margin-left: 18pt; text-indent: -18pt; font-family: Calibri; -aw-list-padding-sml: 8.22pt;">
                            <span style="width: 8.22pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>u pronajímatele: Kolín IV, Heverova 191;</span>
                        </li>
                        <li style="margin-left: 18pt; text-indent: -18pt; font-family: Calibri; -aw-list-padding-sml: 8.22pt;">
                            <span style="width: 8.22pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span>u nájemníka: Kolín IV, Komenského 375.</span>
                        </li>
                    </ol>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family: Calibri;">Dojde-li v</span>
                    <span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">průběhu trvání vztahu z</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">této smlouvy ke změně adresy pro doručování u některého z účastníků této smlouvy je tento účastník povinen změnu v</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">adrese ohlásit písemnou formou druhému účastníkovi bez zbytečného odkladu. Nesplní-li účastník této smlouvy povinnost k ohlášení změny v</span>
                    <span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">adrese pro doručování, je druhý účastník oprávněn písemnosti doručovat na poslední známou adresu účastníka, který svou oznamovací povinnost nesplnil.</span>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Jednostranné právní úkony jsou účastníci povinni doručovat si vzájemně „s</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">dodejkou, výhradně do vlastních rukou adresáta“.</span>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Písemnost se považuje za doručenou, i když si ji adresát v</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">úložné době podle poštovního řádu nevyzvedne z</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">
                        jakéhokoliv důvodu, popřípadě pokud adresát přijetí písemnosti odmítne. To platí i pro případy, pokud se adresát nebude zdržovat na adrese pro doručování, aniž by změnu oznámil druhé straně.
                    </span>
                    <span style="font-family: Calibri; -aw-import: spaces;">&#xa0; </span>
                </li>
            </ol>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>XVIII.</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span>Závěrečná ustanovení</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: center; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <ol type="1" class="awlist9" style="margin: 0pt; padding-left: 0pt;">
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Změny této smlouvy jsou možné pouze v písemné formě.</span>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family: Calibri;">Pro případné spory z</span>
                    <span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">této smlouvy sjednávají strany místní příslušnost k Okresnímu soudu v</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">Kolíně, a to i v</span><span style="font-family: Calibri;">&#xa0;</span><span style="font-family: Calibri;">případě, pokud v</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">době zahájení řízení bude žalovaný účastník mít bydliště, sídlo či trvalý pobyt mimo obvod Okresního soudu v</span><span style="font-family: Calibri;">&#xa0;</span>
                    <span style="font-family: Calibri;">Kolíně. </span>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Tato smlouva se vyhotovuje ve třech vyhotoveních, z nichž každé má platnost originálu, jeden obdrží nájemník a dva pronajímatel.</span>
                </li>
                <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; line-height: normal; font-family: 'Times New Roman'; -aw-list-padding-sml: 8.84pt;">
                    <span style="width: 8.84pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: ignore;">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                    <span style="font-family: Calibri;">Obě strany prohlašují, že si tuto smlouvu přečetly a její obsah že odpovídá jejich svobodné a pravé vůli. Na důkaz pravosti připojují své vlastnoruční podpisy.</span>
                </li>
            </ol>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 6pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;">
                <span>v</span><span>&#xa0;</span><span>Kolíně dne 1. ledna 2023</span>
                <span style="-aw-import: spaces;">
                    &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span>
                <span>v</span><span>&#xa0;</span><span>Kolíně dne 1. ledna 2023</span>
            </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;"><span style="width: 304.5pt; display: inline-block; -aw-tabstop-align: left; -aw-tabstop-pos: 304.5pt;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;">
                <span>Nájemník:</span><span style="-aw-import: spaces;">&#xa0;&#xa0; </span>
                <span style="-aw-import: spaces;">
                    &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span>
                <span>Pronajímatel:</span>
            </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;">
                <span style="font-weight: bold;">Ing. Dušan Zahrádka</span>
                <span style="font-weight: bold; -aw-import: spaces;">
                    &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span>
                <span style="font-weight: bold;">Ing. František Pražák, Ph.D.</span>
            </p>
            <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify; line-height: normal;">
                <span style="font-weight: bold; -aw-import: spaces;">
                    &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span>
                <span style="font-weight: bold; -aw-import: spaces;">&#xa0;</span><span>ředitel školy</span>
            </p>
            <p style="margin-top: 0pt; margin-bottom: 10pt; text-align: center; line-height: 115%; font-size: 14pt;"><span style="font-weight: bold; -aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt; text-align: center; line-height: 115%; font-size: 14pt;"><span style="font-weight: bold; -aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            <p style="margin-top: 0pt; margin-bottom: 10pt;"><span style="width: 370.8pt; display: inline-block; -aw-tabstop-align: left; -aw-tabstop-pos: 370.8pt;">&#xa0;</span></p>
            <div style="-aw-headerfooter-type: footer-primary; clear: both;">
                <p style="margin-top: 0pt; margin-bottom: 0pt; line-height: normal;">
                    <span style="font-size: 9pt;">SPŠS a JŠ Kolín IV</span><span style="width: 102.68pt; font-size: 9pt; display: inline-block; -aw-tabstop-align: center; -aw-tabstop-pos: 226.8pt;">&#xa0;</span>
                    <span style="font-size: 9pt;">e-mail: </span><a href="mailto:info@sps-ko.cz" style="text-decoration: none;"><span style="font-size: 9pt; text-decoration: underline; color: #0000ff;">info@sps-ko.cz</span></a>
                    <span style="font-size: 7pt;"> </span><span style="width: 89.69pt; font-size: 7pt; display: inline-block; -aw-tabstop-align: right; -aw-tabstop-pos: 453.6pt;">&#xa0;</span>
                    <span style="font-size: 9pt;">č.ú: 9276170247/0100</span>
                </p>
                <p style="margin-top: 0pt; margin-bottom: 0pt; line-height: normal; font-size: 9pt;">
                    <span>Heverova 191</span><span style="width: 129.73pt; display: inline-block; -aw-tabstop-align: center; -aw-tabstop-pos: 226.8pt;">&#xa0;</span><span>telefon: 321</span><span>&#xa0;</span><span>743</span>
                    <span>&#xa0;</span><span>000</span><span style="width: 122.22pt; display: inline-block; -aw-tabstop-align: right; -aw-tabstop-pos: 453.6pt;">&#xa0;</span><span>IČO 486</span><span>&#xa0;</span><span>658 60</span>
                </p>
                <p style="margin-top: 0pt; margin-bottom: 0pt; line-height: normal;">
                    <span style="font-size: 9pt;">280 02 Kolín IV</span><span style="width: 135.25pt; font-size: 9pt; display: inline-block; -aw-tabstop-align: center; -aw-tabstop-pos: 226.8pt;">&#xa0;</span>
                    <a href="http://www.sps-ko.cz" style="text-decoration: none;"><span style="font-size: 9pt; text-decoration: underline; color: #0000ff;">www.sps-ko.cz</span></a>
                    <span style="width: 134.25pt; font-size: 9pt; display: inline-block; -aw-tabstop-align: right; -aw-tabstop-pos: 453.6pt;">&#xa0;</span><span style="font-size: 9pt;">IZO 600007171</span>
                </p>
                <p style="margin-top: 0pt; margin-bottom: 0pt; line-height: normal; font-size: 9pt;">
                    <span style="width: 226.8pt; display: inline-block; -aw-tabstop-align: center; -aw-tabstop-pos: 226.8pt;">&#xa0;</span>
                    <span style="width: 226.8pt; display: inline-block; -aw-tabstop-align: right; -aw-tabstop-pos: 453.6pt;">&#xa0;</span>
                </p>
                <p style="margin-top: 0pt; margin-bottom: 0pt; line-height: normal; font-size: 9pt;"><span style="-aw-import: ignore;">&#xa0;</span></p>
            </div>
        </div>
    </body>
</html>
`
