import { useRef, useState } from "react"
import { Icon } from "@/assets"
import { cn } from "@/lib/utils"

type UploadFileProps = {
  onUpload: (files: File[] | null) => void
  value?: File[] | null
  itemDesc?: string
  sizeDesc?: string
  dashed?: boolean
  multiple?: boolean
  accept?: string
}

export function UploadFile({
  onUpload,
  accept,
  value,
  sizeDesc = "100 MB",
  itemDesc = "your documents",
  dashed = false,
  multiple = false,
}: UploadFileProps) {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [isDragging, setIsDragging] = useState(false)

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      onUpload(Array.from(e.target.files))
    }

    e.target.value = ""
  }

  function handleDrop(e: React.DragEvent<HTMLLabelElement>) {
    e.preventDefault()
    const droppedFiles = e.dataTransfer.files
    console.log(droppedFiles)

    if (droppedFiles && droppedFiles.length > 0) {
      onUpload(Array.from(droppedFiles))
    }
  }

  return (
    <label
      className={cn(
        "flex flex-col gap-2 items-center border rounded-12 p-10 hover:cursor-pointer",
        dashed && "border border-dashed border-[#B6CADE]",
        isDragging && "border-2 border-blue-200",
      )}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          fileInputRef.current?.click()
        }
      }}
      onDrop={handleDrop}
      onDragLeave={(e) => {
        setIsDragging(false)
        e.preventDefault()
        e.stopPropagation()
      }}
      onDragOver={(e) => {
        setIsDragging(true)

        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <Icon name="Cloud" />

      <p className="text-18 leading-6 text-black/90 text-center p-2">
        <span className="text-blue-200 underline">Click to upload</span> or drag
        and drop {itemDesc}
      </p>

      <p className="text-14 leading-6 text-black/60">
        Maximum size: {sizeDesc}
      </p>

      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        value={
          value && value.length > 0
            ? `${value.length} files selected`
            : value
              ? value[0].name
              : undefined
        }
        accept={accept}
        onChange={handleFileChange}
        multiple={multiple}
      />
      {/* TODO: to upload a directory: */}
      {/*<input type="file" ref={fileInputRef} className="hidden" onChange={handleFileChange} directory="" allowdirs="" webkitdirectory="" />*/}
    </label>
  )
}
