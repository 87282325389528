import { useGetActivityQuery } from "@/api/resources"
import Folder from "@/components/folders"

export default function RecentFolders() {
  const { data = [] } = useGetActivityQuery()
  const folders = data.filter((x) => x.resource_type === "directory")

  return (
    <div className="flex flex-col gap-4">
      <h3 className="font-500 text-18 leading-6 text-black">Recent Folders</h3>
      <div className="grid gap-4 grid-cols-4">
        {folders.length > 0 ? (
          folders.map((folder, idx) => (
            <Folder
              key={idx}
              link={`/folders/${folder.directory_id}`}
              folder={folder}
            />
          ))
        ) : (
          <p className="text-gray text-center col-span-4">
            You have no recent folders...
          </p>
        )}
      </div>
    </div>
  )
}
