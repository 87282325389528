import { Icon } from "@/assets"
import SavedSearches from "@/components/savedSearches"
import { savedSearchesData } from "@/data/savedSearches"

interface MyLibraryProps {
  toggleModal?: any
  onSelect: any
}

// TODO: this file is likely unnecessary
export default function MyLibrary({ onSelect }: MyLibraryProps) {
  return (
    <div className="overflow-y-scroll">
      {/* TODO: apply this style to modal with some changes */}
      {/*<div className="flex justify-between items-center pb-6">*/}
      {/*  <h3 className="font-400 text-22 leading-[27.72px] ">My Library</h3>*/}
      {/*  <button onClick={toggleModal}>*/}
      {/*    <Icon name={"Close24"} />*/}
      {/*  </button>*/}
      {/*</div>*/}
      <SavedSearches searchData={savedSearchesData} onSelect={onSelect} />
    </div>
  )
}
