import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"

interface ModalProps {
  children: React.ReactNode
  isOpen: boolean
  setOpen: () => void
  title?: string
  description?: string
  cls: string
}

export default function Modal({
  children,
  isOpen,
  setOpen,
  title,
  description,
  cls,
}: ModalProps) {
  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      <DialogContent className={`max-h-[98%] overflow-y-scroll ${cls ?? ""}`}>
        {(title || description) && (
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
            <DialogDescription>{description}</DialogDescription>
          </DialogHeader>
        )}
        <div className="grid gap-4 py-4">{children}</div>
        {/*<DialogFooter>*/}
        {/*  <Button type="submit">Save changes</Button>*/}
        {/*</DialogFooter>*/}
      </DialogContent>
    </Dialog>
  )
}
