import axios, { AxiosError } from "axios"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { API_URL } from "@/config"

interface LoginData {
  email: string
  password: string
}

export const loginThunk = createAsyncThunk(
  "auth/login",
  async (loginData: LoginData, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams()
      params.append("username", loginData.email)
      params.append("password", loginData.password)
      const { data } = await axios.post(`${API_URL}/auth/login`, params)

      return data
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.data
      ) {
        return rejectWithValue(error.response.data)
      } else if (error instanceof Error) {
        return rejectWithValue(error.message)
      } else {
        return rejectWithValue("An unknown error occurred")
      }
    }
  },
)
