import { useState } from 'react'
import { Button } from "@/components/ui/button"
import { LayoutGrid, LayoutList } from "lucide-react"

export default function ViewToggleButtons({ isGridView, setIsGridView }) {

  return (
    <div className="inline-flex rounded-md shadow-sm" role="group">
      <Button
        variant={isGridView ? "default" : "outline"}
        size="icon"
        onClick={() => setIsGridView(true)}
        className="rounded-r-none"
        aria-label="Switch to grid view"
        aria-pressed={isGridView}
      >
        <LayoutGrid className="h-4 w-4" />
      </Button>
      <Button
        variant={!isGridView ? "default" : "outline"}
        size="icon"
        onClick={() => setIsGridView(false)}
        className="rounded-l-none border-l-0"
        aria-label="Switch to list view"
        aria-pressed={!isGridView}
      >
        <LayoutList className="h-4 w-4" />
      </Button>
    </div>
  )
}