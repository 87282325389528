import { useView } from "@/context/ViewContext"
import ReadOnlyEditor from "./readOnlyEditor"
import ReadWriteEditor from "./readWriteEditor"
import {
  SemanticMatch,
  useDocumentContext,
} from "@/components/left/DocumentContext"
import {
  Highlight,
  PdfHighlighter,
  PdfHighlighterUtils,
  useHighlightContainerContext,
  ViewportHighlight,
  PdfLoader,
  TextHighlight,
  Scaled,
  ScaledPosition,
  MonitoredHighlightContainer,
} from "react-pdf-highlighter-extended"
import { API_URL } from "@/config"
import { useAppSelector } from "@/redux/hooks"
import { useEffect, useMemo, useRef, useState } from "react"
import { PDF1 } from "@/components/PdfWrapper";

const colors = {
  // previously tried gradient 011 at https://webgradients.com/
  // -> https://coolors.co/gradient-palette/ and take colors in between
  // lightGreen: "linear-gradient(120deg, #BFF58699 0%, #ABED9499 100%)",
  lightGreen: "rgb(134, 239, 172, 0.6)",
  darkGreen: "rgb(134, 239, 172, 1.0)",
  darkRed: "transparent", //"#FF4D4D",
  lightRed: "transparent", //"#FFB3B3",
  lightGray: "#d8dfe6", // that's "#3C608333" with alpha=0.2, //"#3C608366",
  darkGray: "transparent", //"#99B5D1",
}

interface MatchHighlight extends Highlight {
  match: SemanticMatch
}

const MatchTextHighlight = ({
  activeMatch,
  hoveredChunk,
  onMouseEnter,
}: {
  activeMatch: number | null
  hoveredChunk: number | null
  onMouseEnter?: (data: ViewportHighlight<MatchHighlight>) => void
}) => {
  const { highlight, isScrolledTo } =
    useHighlightContainerContext<MatchHighlight>()

  const isMachineMatched = highlight.match.value === 1
  const isUserValidated = highlight.match.isUserValidated === 1
  const isHovered = hoveredChunk === highlight.match.index
  const isActive = activeMatch === highlight.match.index

  let color: keyof typeof colors | undefined = undefined

  if (isUserValidated) {
    color = isActive ? "darkGreen" : "lightGreen"
  } else {
    if (isMachineMatched) {
      color = isActive ? "darkRed" : "lightRed"
    } else if (isHovered) {
      color = "lightGray"
    } else if (isActive) {
      color = "darkGray"
    }
  }
  return (
    <MonitoredHighlightContainer
      key={highlight.id}
      onMouseEnter={() => onMouseEnter?.(highlight)}
    >
      <TextHighlight
        isScrolledTo={isScrolledTo}
        highlight={highlight}
        style={{
          backgroundColor: color ? colors[color] : "transparent",
          borderRadius: "5px",
          boxShadow: isActive
            ? "rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, #a6c0fe 0px 0px 0px 2px"
            : undefined,
        }}
      />
    </MonitoredHighlightContainer>
  )
}

const PdfView = () => {
  const {
    documentId,
    semanticSearchData: data,
    activeMatch,
    allMatches,
  } = useDocumentContext()
  const authToken = useAppSelector((state) => state.auth.accessToken)
  const pdfDocument = useMemo(
    () => ({
      url: `${API_URL}/documents/${documentId}?foo`,
      httpHeaders: {
        Authorization: `Bearer ${authToken}`,
        Accept: "application/octet-stream",
      },
    }),
    [documentId, authToken],
  )

  const { splitView } = useView()
  const [highlights, setHighlights] = useState<MatchHighlight[]>([])
  const [hoveredChunk, setHoveredChunk] = useState<number | null>(null)

  const triggeredRef = useRef(false)
  const highlighterUtilsRef = useRef<PdfHighlighterUtils>()

  useEffect(() => {
    if (allMatches) {
      const newHighlights = allMatches.flatMap((match) => {
        if (!data || !data.text_chunks || !data.page_xyxy) return []

        const rects = data.text_chunks[match.index].components.map(
          ({ page, xyxy: [x1, y1, x2, y2] }): Scaled => {
            const pageBox = data.page_xyxy![page]
            const pageWidth = pageBox[2] - pageBox[0]
            const pageHeight = pageBox[3] - pageBox[1]

            return {
              pageNumber: page + 1,
              width: pageWidth,
              height: pageHeight,
              x1: x1 - 5.0,
              x2: x2 + 5.0,
              y1: y1 - 5.0,
              y2: y2 + 5.0,
            }
          },
        )

        const componentsGroupedByPage: Record<number, ScaledPosition> =
          rects.reduce(
            (
              acc: Record<number, ScaledPosition>,
              rect: Scaled,
            ): Record<number, ScaledPosition> => {
              if (!acc[rect.pageNumber]) {
                acc[rect.pageNumber] = {
                  rects: [],
                  boundingRect: {
                    x1: Infinity,
                    x2: -Infinity,
                    y1: Infinity,
                    y2: -Infinity,
                    width: rect.width,
                    height: rect.height,
                    pageNumber: rect.pageNumber,
                  },
                }
              }

              acc[rect.pageNumber].rects.push(rect)
              acc[rect.pageNumber].boundingRect.x1 = Math.min(
                acc[rect.pageNumber].boundingRect.x1,
                rect.x1,
              )
              acc[rect.pageNumber].boundingRect.x2 = Math.max(
                acc[rect.pageNumber].boundingRect.x2,
                rect.x2,
              )
              acc[rect.pageNumber].boundingRect.y1 = Math.min(
                acc[rect.pageNumber].boundingRect.y1,
                rect.y1,
              )
              acc[rect.pageNumber].boundingRect.y2 = Math.max(
                acc[rect.pageNumber].boundingRect.y2,
                rect.y2,
              )

              return acc
            },
            {},
          )

        return Object.values(componentsGroupedByPage).map((group, idx) => ({
          id: `${match.index}-${idx}`,
          type: "text",
          match,
          position: group,
        })) as MatchHighlight[]
      })

      setHighlights(newHighlights)

      // Hacky way to scroll to url target match when the page is loaded
      // We only want this to run once, since running this multiple times
      // will cause the page to scroll to the target match multiple times
      // when scrolling through the pdf document, since the content is lazy loaded
      if (activeMatch !== null && location.hash && !triggeredRef.current) {
        const hi = newHighlights.find((x) => x.match.index === activeMatch)

        if (hi && highlighterUtilsRef.current) {
          highlighterUtilsRef.current.scrollToHighlight(hi)
          triggeredRef.current = true
        }
      }
    }
  }, [allMatches, hoveredChunk, activeMatch])

  useEffect(() => {
    if (activeMatch !== null) {
      const hi = highlights.find((x) => x.match.index === activeMatch)

      if (hi) {
        highlighterUtilsRef.current?.scrollToHighlight(hi)
      } else {
        console.warn("Highlight item hasn't been loaded yet!!!")
      }
    }
  }, [activeMatch])

  return (
    <div className={`py-0 mr-0 max-h-screen w-1/2 overflow-y-scroll`}>
      <div
        className={` ${
          splitView ? "p-8" : "px-0 pb-0"
        } bg-bgSecondary rounded-10 flex flex-col`}
      >
        <section className="bg-white">
          <PdfLoader document={pdfDocument}>
            {(pdfDocument) => (
              <PdfHighlighter
                style={{ border: "1px solid red", width: "50%" }}
                pdfDocument={pdfDocument}
                utilsRef={(_pdfHighlighterUtils) => {
                  highlighterUtilsRef.current = _pdfHighlighterUtils
                }}
                highlights={highlights}
              >
                <MatchTextHighlight
                  activeMatch={activeMatch}
                  hoveredChunk={hoveredChunk}
                  onMouseEnter={(highlight) => {
                    setHoveredChunk(highlight.match.index)
                  }}
                />
              </PdfHighlighter>
            )}
          </PdfLoader>
        </section>
      </div>
    </div>
  )
}

export default function Right({ keywords, setMatches, activeMatch }) {
  const { splitView } = useView()
  const { document, htmlFile } = useDocumentContext()

  return (
    <>
      {/*{document?.file_type === "pdf" && <PdfView />}*/}
      <div className={`py-0 mr-0 max-h-screen w-1/2 overflow-y-scroll`}>
        <div
          className={` ${
            splitView ? "p-8" : "px-0 pb-0"
          } bg-bgSecondary rounded-10 flex flex-col`}
        >
          <PDF1 />
        </div>
      </div>
      {htmlFile && (
        <ReadOnlyEditor
          keywords={keywords}
          setMatches={setMatches}
          activeMatch={activeMatch}
        />
      )}
      {splitView && <ReadWriteEditor />}
    </>
  )
}
