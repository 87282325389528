export type Optional<T> = T | undefined;

export interface Bounds {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

export interface Token {
  x: number;
  y: number;
  height: number;
  width: number;
  text: string;
}

export interface Label {
  text: string;
  color: string;
}

export interface TokenId {
  pageIndex: number;
  tokenIndex: number;
}

/**
 * Returns the provided bounds scaled by the provided factor.
 */
export function scaled(bounds: Bounds, scale: number): Bounds {
  return {
    left: bounds.left * scale,
    top: bounds.top * scale,
    right: bounds.right * scale,
    bottom: bounds.bottom * scale
  };
}

/**
 * Computes a bound which contains all of the bounds passed as arguments.
 */
export function spanningBound(bounds: Bounds[], padding: number = 3): Bounds {
  // Start with a bounding box for which any bound would be
  // contained within, meaning we immediately update maxBound.
  const maxBound: Bounds = {
    left: Number.MAX_VALUE,
    top: Number.MAX_VALUE,
    right: 0,
    bottom: 0
  };

  bounds.forEach((bound) => {
    maxBound.bottom = Math.max(bound.bottom, maxBound.bottom);
    maxBound.top = Math.min(bound.top, maxBound.top);
    maxBound.left = Math.min(bound.left, maxBound.left);
    maxBound.right = Math.max(bound.right, maxBound.right);
  });

  maxBound.top = maxBound.top - padding;
  maxBound.left = maxBound.left - padding;
  maxBound.right = maxBound.right + padding;
  maxBound.bottom = maxBound.bottom + padding;

  return maxBound;
}

/**
 * Returns true if the provided bounds overlap.
 */
export function doOverlap(a: Bounds, b: Bounds): boolean {
  if (a.left >= b.right || a.right <= b.left) {
    return false;
  } else if (a.bottom <= b.top || a.top >= b.bottom) {
    return false;
  }
  return true;
}

export function getPageBoundsFromCanvas(canvas: HTMLCanvasElement): Bounds {
  if (canvas.parentElement === null) {
    throw new Error("No canvas parent");
  }
  const parent = canvas.parentElement;
  const parentStyles = getComputedStyle(canvas.parentElement);

  const leftPadding = parseFloat(parentStyles.paddingLeft || "0");
  const left = parent.offsetLeft + leftPadding;

  const topPadding = parseFloat(parentStyles.paddingTop || "0");
  const top = parent.offsetTop + topPadding;

  const parentWidth =
    parent.clientWidth - leftPadding - parseFloat(parentStyles.paddingRight || "0");
  const parentHeight =
    parent.clientHeight - topPadding - parseFloat(parentStyles.paddingBottom || "0");
  return {
    left,
    top,
    right: left + parentWidth,
    bottom: top + parentHeight
  };
}
