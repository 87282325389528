import { getDocument } from 'pdfjs-dist';
import * as pdfjs from 'pdfjs-dist';
import { useDocumentContext } from "@/components/left/DocumentContext";
import { API_URL } from "@/config";
import { useAppSelector } from "@/redux/hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { PDFDocumentProxy } from "pdfjs-dist/types/display/api";
import { PDFPageRenderer } from "@/pdf/PDFPageRenderer";
import { Page } from "@/pdf/PDFPage";
import { SemanticSearchResult } from "@/api/resources";

// This tells PDF.js the URL the code to load for it's webworker, which handles heavy-handed
// tasks in a background thread. Ideally we'd load this from the application itself rather
// than from the CDN to keep things local.
// TODO (@codeviking): Figure out how to get webpack to package up the PDF.js webworker code.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.7.570/pdf.worker.js`;

const loadPDF = async (urlOrParams: any) => {
  const loadingTask = getDocument(urlOrParams);
  const pdf = await loadingTask.promise;
  return pdf;
};

const renderPDFPage = async (pdf, pageNo) => {
  const page = await pdf.getPage(pageNo);
  const canvas = document.getElementById('pdf-canvas');
  const renderer = new PDFPageRenderer(page, canvas, console.error);
  renderer.render(1.5); // Adjust scale as needed
};

export function PdfWrapper () {
  const authToken = useAppSelector((state) => state.auth.accessToken)
  const { document, htmlFile } = useDocumentContext()

  const params = {
    url: `${API_URL}/documents/${document?.document_id}?foo`,
    httpHeaders: {
      Authorization: `Bearer ${authToken}`,
      Accept: "application/octet-stream",
    },
  }

  useEffect(() => {
    loadPDF(params).then(pdf => renderPDFPage(pdf, 1));
  }, [params]);

  return <canvas id="pdf-canvas" className="border"></canvas>;
}

function getNormalizedAnnotationsForPage(data: SemanticSearchResult, pageNumber: number) {
  if (!data || !data.page_xyxy || !data.text_chunks) {
    return []
  }
  const pageBbox = data.page_xyxy[pageNumber]
  const pageW = pageBbox[2] - pageBbox[0];
  const pageH = pageBbox[3] - pageBbox[1];
  return data.text_chunks?.flatMap((chunk, index) => {
    return chunk.components.filter((component) => component.page === pageNumber).map((component, i) => ({
      ...component,
      index,
      xyxy: [
        component.xyxy[0] / pageW,
        component.xyxy[1] / pageH,
        component.xyxy[2] / pageW,
        component.xyxy[3] / pageH
      ],
      isLeading: i === 0,
    }))
  })
}

export const PDF1 = () => {
  const {
    documentId,
    semanticSearchData: data,
    activeMatch,
    allMatches,
  } = useDocumentContext()

  const authToken = useAppSelector((state) => state.auth.accessToken)
  const { document, htmlFile } = useDocumentContext()
  const [doc, setDoc] = useState<null|PDFDocumentProxy>(null)
  const [isLoading, setLoading] = useState(false)

  const normalizedAnnotations = useMemo(() => doc ? Array.from({ length: doc.numPages }, (_, i) => getNormalizedAnnotationsForPage(data, i)): null, [doc, data])
  const containerRef = useRef<HTMLDivElement|null>(null)

  const params = {
    url: `${API_URL}/documents/${document?.document_id}?foo`,
    httpHeaders: {
      Authorization: `Bearer ${authToken}`,
      Accept: "application/octet-stream",
    },
  }

  useEffect(() => {
    if (!doc && !isLoading && API_URL && document) {
      setLoading(true)
      const loadingTask = loadPDF(params)
      const x = loadingTask as Promise<PDFDocumentProxy>
      x.then(y => {
        setDoc(y)
      })
    }

    // loadPDF(params).then(pdf => renderPDFPage(pdf, 1));
  }, [params]);


  function scrollToSelection(pageAnnotations, selectedIndex) {
    if (pageAnnotations && containerRef.current) {
      const pageIx = pageAnnotations.findIndex((page) => page.some((annotation) => annotation.index === selectedIndex))
      if (pageIx !== -1) {
        const domPage = containerRef.current?.children[pageIx]
        const rect = domPage.getBoundingClientRect()
        const isVisible = !(rect.top > window.innerHeight || rect.bottom < 0)
        if (!isVisible) {
          containerRef.current?.children[pageIx].scrollIntoView({ behavior: 'smooth' })
        }
      }
    }
  }

  useEffect(() => {
    if (activeMatch !== null) {
      scrollToSelection(normalizedAnnotations, activeMatch)
    }
  }, [activeMatch])

  return <div className="overflow-scroll w-full" ref={containerRef}>
    {doc && Array.from({ length: doc.numPages }, (_, i) => (
      <Page key={i} pdfPage={doc.getPage(i+1)} onError={console.log} annotations={normalizedAnnotations ? normalizedAnnotations[i]: []} />
    ))}
  </div>

};
