import { Icon } from "@/assets"
import { DeleteFolderDialog } from "@/components/Project/project/DeleteFolderDialog"
import { useState } from "react"
import { DirectorySchema } from "@/api/resources"
import { Button } from "@/components/ui/button"
import { CreateOrRenameFolderDialog } from "@/components/Project/project/CreateOrRenameFolderDialog"
import { useOnUploadFileChange } from "@/components/Project/ProjectContext"
import { Modal } from "@/elements"
import { UploadFile } from "@/components/UploadFile"
import ShareModal from "@/components/Project/project/ProjectHeader/shareModal"

interface ActionProps {
  folder?: DirectorySchema
}

export default function Action({ folder }: ActionProps) {
  const [isRenameModal, setRenameModal] = useState(false)
  const [isDeleteModal, setDeleteModal] = useState(false)
  const [openDocUploading, setOpenDocUploading] = useState(false)
  const [openShare, setOpenShare] = useState(false)
  const { handleFileChange } = useOnUploadFileChange(folder?.directory_id)

  return (
    <div className="flex justify-between">
      <div className="flex items-center gap-2 text-24 text-black font-500">
        <Icon name="Folder" />
        {folder?.display_name}
      </div>

      <div className="flex items-center text-14 leading-6">
        <Button
          variant="ghost"
          className="px-4 flex items-center gap-1 font-500 text-blue-200"
          onClick={() => {
            setOpenDocUploading(true)
          }}
        >
          <Icon name="Export" />
          Upload Document
        </Button>
        <div className="h-4 border-l border-[#B6CADE]" role="none"></div>
        <button
          className="px-4 flex items-center gap-1 font-500"
          style={{ color: "#4975A1" }}
          onClick={() => {
            setOpenShare(true)
          }}
        >
          <Icon name="Share" />
          Share
        </button>
        <div className="h-4 border-l border-[#B6CADE]" role="none"></div>
        <Button
          variant="ghost"
          className="px-4 flex items-center gap-1 font-500"
          style={{ color: "#4975A1" }}
          onClick={() => {
            setRenameModal(true)
          }}
        >
          <Icon name="Edit" />
          Rename
        </Button>
        <div className="h-4 border-l border-[#B6CADE]" role="none"></div>
        <Button
          variant="ghost"
          className="px-4 flex items-center gap-1 font-500 text-background-lightRed"
          onClick={() => {
            setDeleteModal(true)
          }}
        >
          <Icon name="Delete" />
          Delete
        </Button>
      </div>
      {folder && (
        <>
          <CreateOrRenameFolderDialog
            projectId={folder.project_id}
            folderId={folder.directory_id}
            initialName={folder.display_name}
            action="rename"
            open={isRenameModal}
            onOpenChange={setRenameModal}
          />
          <DeleteFolderDialog
            directoryId={folder.directory_id}
            open={isDeleteModal}
            onOpenChange={setDeleteModal}
          />
          <Modal
            cls="max-w-xl"
            title="Upload Documents"
            isOpen={openDocUploading}
            setOpen={setOpenDocUploading}
          >
            {/* TODO: take the title and header style from UploadModal! */}
            <UploadFile onUpload={handleFileChange} dashed multiple />
          </Modal>
          <Modal isOpen={openShare} setOpen={setOpenShare} cls="max-w-xl">
            <ShareModal
              projectId={folder.project_id}
              toggleShare={() => {
                setOpenShare(!openShare)
              }}
            />
          </Modal>
        </>
      )}
    </div>
  )
}
