"use client"
import { useState } from "react"
import Projects from "../projects"
import { CreateOrRenameProjectDialog } from "@/components/allProjects/CreateOrRenameProjectDialog"
import { useGetProjectsQuery } from "@/api/resources"
import { ProjectSchema } from "@/api/resources/types"

export default function AllProjects() {
  const [openModal, setOpenModal] = useState(false)

  const { data, isLoading, isSuccess, isError } = useGetProjectsQuery()
  const userProjects = data as ProjectSchema[]

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <h3 className="font-500 text-24 text-black">Projects</h3>
        <CreateOrRenameProjectDialog
          showButton
          action="create"
          open={openModal}
          onOpenChange={setOpenModal}
        />
      </div>

      {isSuccess && (
        <Projects
          projects={userProjects}
          className="border border-border-gray rounded-14"
        />
      )}
    </div>
  )
}
