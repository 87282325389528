"use client"
import { Icon } from "@/assets"
import { savedSearchesData } from "@/data/savedSearches"
import { useState } from "react"
import SavedSearches from "../savedSearches"
import { searchType } from "@/types/search"
import { CreateOrEditSearchModal } from "@/components/mySavedSearches/CreateOrEditSearchModal"
import { Button } from "@/components/ui/button"
import { SavedSearchSchema } from "@/api/resources/types"

export default function MySavedSearches() {
  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <div
      className={`pt-8 wrapper ${isModalOpen && "overflow-hidden h-screen"}`}
    >
      <CreateOrEditSearchModal isOpen={isModalOpen} setOpen={setModalOpen} />

      <div className="flex justify-between items-center pb-6">
        <h3 className="font-500 text-24 text-gray-500 ">Saved Searches</h3>
        <Button
          variant="ghost"
          onClick={() => setModalOpen(true)}
          className="flex items-center gap-1.5 font-500 text-16 text-blue-200 "
        >
          <Icon name="Plus" />
          Create New
        </Button>
      </div>
      <SavedSearches
        onSelect={() => {
          /* do nothing */
        }}
      />
    </div>
  )
}
