import { useEffect, useRef, useState } from "react"

import { useDocumentContext } from "@/components/left/DocumentContext"
import { cn } from "@/lib/utils"
import { Checkbox } from "@/components/ui/checkbox"
import { Button } from "@/components/ui/button"
import { useUpdateSemanticLabelsMutation } from "@/api/resources"
import { toast } from "sonner"
import { useNavigate } from "react-router-dom"

export default function CriteriaMatch() {
  const navigate = useNavigate()

  const {
    activeMatch,
    setActiveMatch,
    semanticSearchClass,
    allMatches,
    setMatchUserLabel,
    areMatchesModified,
    persistUserLabels,
  } = useDocumentContext()
  const [isShowAll, setIsShowAll] = useState(false)
  const targetMatch = useRef<HTMLDivElement|null>(null)
  const triggeredRef = useRef<boolean>(false)

  const matchedOnly = allMatches.filter(
    (x) => x.value === 1 || x.isUserValidated === 1,
  )
  const matchedOnlyIdx = matchedOnly.map((x) => x.index)
  const baseMatches = isShowAll ? allMatches : matchedOnly

  const containerRef = useRef<HTMLDivElement|null>(null)

  const filteredBufferMatches = isShowAll
    ? allMatches
    : allMatches.filter(
        (x) => matchedOnlyIdx.includes(x.index) || x.isUserValidated === 1,
      )

  useEffect(() => {
    // Workaround to make the selected matched line from the project's
    // semantic tab to be scrolled to when the page loads, also we only want
    // this to trigger once, or else it will be scrolled to whenever we
    // check / uncheck other lines
    if (
      activeMatch !== null &&
      location.hash &&
      targetMatch.current &&
      !triggeredRef.current
    ) {
      targetMatch.current?.scrollIntoView({
        block: "center",
        inline: "center",
      })
      triggeredRef.current = true
    }
  }, [filteredBufferMatches])

  useEffect(() => {
    let urlFragment = `?provision=${semanticSearchClass}`
    if (activeMatch !== null && !isNaN(activeMatch)) {
      urlFragment += `#${activeMatch}`
    }

    navigate(urlFragment, { replace: true })

    return () => {
      // Clear url params when navigating away
      navigate("", { replace: true })
    }
  }, [activeMatch, semanticSearchClass])

  useEffect(() => {
    if (activeMatch !== null && containerRef.current) {
      const domMatches = containerRef.current?.getElementsByClassName(`match-element-${activeMatch}`);
      if (domMatches.length > 0) {
        const rect = domMatches[0].getBoundingClientRect();
        if (rect.top < 0 || rect.bottom > window.innerHeight) {
          domMatches[0]?.scrollIntoView()
        }
      }
    }
  }, [activeMatch])

  return (
    <div className="pt-6 flex flex-col gap-4" ref={containerRef}>
      <div className="flex justify-between items-center">
        <p className="font-400 text-16 leading-6 text-gray-400">
          <span className="text-black">{baseMatches.length}</span> matches found
        </p>

        <div className="flex gap-2">
          <Button
            variant="outline"
            onClick={() => {
              setIsShowAll(!isShowAll)
              setActiveMatch(null)
            }}
          >
            {isShowAll ? "Show Match Only" : "Show all"}
          </Button>
          <Button
            disabled={!areMatchesModified}
            onClick={persistUserLabels}
          >
            Save
          </Button>
        </div>
      </div>

      {filteredBufferMatches.map((match) => (
        <div
          key={match.index}
          ref={activeMatch === match.index ? targetMatch : null}
          className={cn(
            `match-element-${match.index}`,
            "flex flex-col gap-4 rounded-9 p-4 font-400 text-16 leading-[20.16px] text-ellipsis whitespace-nowrap overflow-hidden cursor-pointer transition-colors",
            match.isUserValidated
              ? activeMatch === null || activeMatch !== match.index
                ? "bg-green-200/50 text-green-800"
                : "bg-green-300 text-green-800"
              : match.value === 1
                ? activeMatch === null || activeMatch !== match.index
                  ? "bg-red-200/20 text-red-800"
                  : "bg-red-200 text-red-800"
                : activeMatch === null || activeMatch !== match.index
                  ? "bg-gray-100 text-gray-900"
                  : "bg-gray-200 text-gray-900",
          )}
          onClick={() => {
            setActiveMatch(match.index === activeMatch ? null : match.index)
          }}
        >
          <div className="text-inherit flex items-center gap-2">
            <p
              className={cn(
                "line-clamp-2 text-pretty w-[calc(100%-16px)]",
                "text-sm leading-4 font-400",
                `${activeMatch === match.index && "text-black line-clamp-none whitespace-pre-wrap leading-normal"}`,
              )}
            >
              {match.text}
            </p>

            <Checkbox
              checked={match.isUserValidated === 1}
              className="data-[state='checked']:bg-green-500 data-[state='checked']:text-white bg-white border-gray-400"
              onClick={(e) => {
                e.stopPropagation()
                setMatchUserLabel(match.index, !(match.isUserValidated === 1))
              }}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
