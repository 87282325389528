import axios from "axios"
import { useState } from "react"
import { useAppSelector } from "@/redux/hooks"
import { API_URL } from "@/config"

// export interface UploadFileParams {
//   projectId: string
//   file: FormData
// }

type UploadStatus = "in_progress" | "pending" | "done" | "error"

export const useUploadFile = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [status, setStatus] = useState<UploadStatus>("pending")
  const [progress, setProgress] = useState(0)

  const accessToken = useAppSelector((state) => state.auth.accessToken)
  if (accessToken == null) {
    return null
  }

  const uploadFn = async (
    projectId: string,
    formData: FormData,
    onProgress?: (event: any) => void,
  ) => {
    setIsLoading(true)
    setIsError(false)
    setIsSuccess(false)
    setStatus("pending")
    setProgress(0)
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    }
    const onUploadProgress = (event) => {
      // TODO: we don't want this anymore
      const progress = event.loaded / event.total
      setProgress(progress)
      setStatus("in_progress")
      //
      if (onProgress) onProgress(event)
    }
    const url = `${API_URL}/projects/${projectId}/documents`
    try {
      const response = await axios.post(url, formData, {
        headers,
        onUploadProgress,
      })
      setIsSuccess(true)
      setStatus("done")
    } catch (e) {
      setIsError(true)
      setStatus("error")
      throw e
    } finally {
      setIsLoading(false)
    }
  }

  return { uploadFn, isLoading, isSuccess, isError, status, progress }
}
