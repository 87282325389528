import { Icon } from "@/assets"
import { useState } from "react"
import { SavedSearchSchema } from "@/api/resources/types"

interface CreateSearchProps {
  closeModal: () => void
  children: React.ReactNode
  handleSave: (
    e: {
      preventDefault: () => void
    },
    name: string,
  ) => void
  savedSearch?: SavedSearchSchema
}

export default function CreateSearch({
  closeModal,
  children,
  handleSave,
  savedSearch,
}: CreateSearchProps) {
  const [searchName, setSearchName] = useState<string>(
    savedSearch?.search_name ?? "",
  )
  const isEditing = !!savedSearch

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchName(e.target.value)

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      handleSave(e, searchName)
      setSearchName("")
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSave(e, searchName)
    setSearchName("")
  }

  return (
    <form
      className="bg-white flex flex-col gap-6"
      onSubmit={(e) => handleSubmit(e)}
    >
      <h3 className="text-22 leading-[27.72px] text-gray-500 text-center">
        {/*TODO: backend returns 500 for editing*/}
        {isEditing ? "Edit Saved Search" : "Create Saved Search"}
      </h3>

      <input
        type="text"
        required
        value={searchName}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        placeholder="Enter Name of the saved search"
        className="border border-gray-200 rounded-12 p-4 text-15 leading-[18.9px] placeholder-text-gray-200 font-500 text-gray-500"
      />

      <div>
        <p className="text-gray-500 font-300 text-20 leading-[48px] text-center">
          What do you want to identify?
        </p>

        {children}
      </div>

      <div className="flex justify-between items-center gap-2">
        <button className="lg-dis-btn w-1/2" onClick={closeModal}>
          Cancel
        </button>
        <button
          className="lg-pri-btn w-1/2"
          // onClick={() => handleSave(searchName)}
          type="submit"
        >
          {isEditing ? "Update" : "Create"}
        </button>
      </div>

      {/*{savedSearch && (*/}
      {/*  <button*/}
      {/*    onClick={() => setSearchName("")}*/}
      {/*    className="flex gap-1 text-background-lightRed font-500 text-14 leading-6 justify-center"*/}
      {/*  >*/}
      {/*    <Icon name="Delete" />*/}
      {/*    Delete Saved Search*/}
      {/*  </button>*/}
      {/*)}*/}
    </form>
  )
}
