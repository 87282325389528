import { useCallback, useEffect, useState } from "react"

import {
  $isParagraphNode,
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  TextFormatType,
} from "lexical"

import {
  type HeadingTagType,
  $createHeadingNode,
  $isHeadingNode,
} from "@lexical/rich-text"
import { $setBlocksType } from "@lexical/selection"
import { mergeRegister, $findMatchingParent } from "@lexical/utils"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"

import {
  FontBoldIcon,
  FontItalicIcon,
  ReloadIcon,
  UnderlineIcon,
} from "@radix-ui/react-icons"
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group"
import { Separator } from "@/components/ui/separator"
import { Button } from "@/components/ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { cn } from "@/lib/utils"

type BlockType = HeadingTagType | "p"

export default function ToolbarPlugin({ className }: { className?: string }) {
  const [editor] = useLexicalComposerContext()
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [formats, setFormats] = useState<TextFormatType[]>([])
  const [blockNode, setBlockNode] = useState<BlockType>()

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection()

    if ($isRangeSelection(selection)) {
      const formats: TextFormatType[] = []

      /* TEXT SELECTION FORMATS */
      if (selection.hasFormat("bold")) {
        formats.push("bold")
      }

      if (selection.hasFormat("italic")) {
        formats.push("italic")
      }

      if (selection.hasFormat("underline")) {
        formats.push("underline")
      }

      setFormats(formats)

      /* BLOCK SELECTION FORMATS */
      // Get the enclosing node of the selection
      const node = selection.anchor.getNode()

      // Find the enclosing block element of the selection
      let element =
        node.getKey() === "root"
          ? node
          : $findMatchingParent(node, (e) => {
              const parent = e.getParent()
              return parent !== null && $isRootOrShadowRoot(parent)
            })

      // If the node is null, it means the selection is not within a block element
      if (element === null) {
        element = node.getTopLevelElementOrThrow()
      }

      // Get the editor DOM instance of the block element
      const elementDOM = editor.getElementByKey(element.getKey())

      if (elementDOM !== null) {
        if ($isHeadingNode(element)) {
          setBlockNode(element.getTag())
        } else if ($isParagraphNode(element)) {
          setBlockNode("p")
        }
      }
    }
  }, [])

  const formatParagraph = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection()
      $setBlocksType(selection, () => $createParagraphNode())
    })
  }, [editor])

  const formatHeading = useCallback(
    (headingLevel: HeadingTagType) => {
      editor.update(() => {
        const selection = $getSelection()
        $setBlocksType(selection, () => $createHeadingNode(headingLevel))
      })
    },
    [editor],
  )

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          $updateToolbar()
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload)
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload)
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
    )
  }, [editor, $updateToolbar])

  return (
    <section
      className={cn("flex items-center justify-center gap-1 h-full", className)}
    >
      <div role="group" className="flex gap-1">
        <Button
          className="h-9 px-3"
          variant="ghost"
          disabled={!canUndo}
          onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
        >
          <ReloadIcon className="transform -scale-x-100" />
        </Button>

        <Button
          className="h-9 px-3"
          variant="ghost"
          disabled={!canRedo}
          onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
        >
          <ReloadIcon />
        </Button>
      </div>

      <Separator
        orientation="vertical"
        className="bg-gray-300 dark:bg-gray-300 h-3/4 mx-1"
      />

      <ToggleGroup
        type="multiple"
        className="justify-start"
        value={formats}
        onValueChange={(values) => setFormats(values as TextFormatType[])}
      >
        <ToggleGroupItem
          value="bold"
          aria-label="Toggle Bold"
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")}
        >
          <FontBoldIcon />
        </ToggleGroupItem>
        <ToggleGroupItem
          value="italic"
          aria-label="Toggle Italic"
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")}
        >
          <FontItalicIcon />
        </ToggleGroupItem>
        <ToggleGroupItem
          value="underline"
          aria-label="Toggle Underline"
          onClick={() =>
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")
          }
        >
          <UnderlineIcon />
        </ToggleGroupItem>
      </ToggleGroup>

      <Separator
        orientation="vertical"
        className="bg-gray-300 dark:bg-gray-300 h-3/4 mx-1"
      />

      <Select
        value={blockNode as string | undefined}
        onValueChange={(value) => {
          if (value === "p") {
            formatParagraph()
          } else {
            formatHeading(value as HeadingTagType)
          }

          setBlockNode(value as BlockType)
        }}
      >
        <SelectTrigger className="min-w-[112px] w-min">
          <SelectValue placeholder="Heading" />
        </SelectTrigger>

        <SelectContent>
          <SelectItem value="h1">Heading 1</SelectItem>
          <SelectItem value="h2">Heading 2</SelectItem>
          <SelectItem value="h3">Heading 3</SelectItem>
          <SelectItem value="h4">Heading 4</SelectItem>
          <SelectItem value="h5">Heading 5</SelectItem>
          <SelectItem value="h6">Heading 6</SelectItem>
          <SelectItem value="p">Paragraph</SelectItem>
        </SelectContent>
      </Select>
    </section>
  )
}
