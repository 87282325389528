import { Project } from "@/types/project"
import { StatusEnum } from "@/types/status"
import { folders } from "./folders"
import { recentDocuments } from "./recentDocuments"
import { ProjectSchema } from "@/api/resources/types"
import { foldersType } from "@/types/folder"
import { ProjectDocument } from "@/types/document"
import { ProjectContents } from "@/api/resources"

// TODO: ProjectContents X ProjectSchema

const contents = [...folders, ...recentDocuments]

export const recentProjects: ProjectContents[] = [
  {
    resource_type: "project",
    project_id: "1",
    display_name: "Non-Disclosure",
    created_at: "",
    role: "editor",
    status: StatusEnum.done,
    contents,
  },
  {
    resource_type: "project",
    project_id: "2",
    display_name: "X Company Law",
    created_at: "",
    role: "editor",
    status: StatusEnum.untouchable,
    contents: folders,
  },
  {
    resource_type: "project",
    project_id: "3",
    display_name: "Judgement",
    created_at: "",
    role: "editor",
    status: StatusEnum.inProgress,
    contents: recentDocuments,
  },
  {
    resource_type: "project",
    project_id: "4",
    display_name: "Non-Disclosure 2",
    created_at: "",
    role: "editor",
    status: StatusEnum.done,
    contents: [],
  },
]

export const allProjects: ProjectContents[] = [
  ...recentProjects,
  ...recentProjects,
  ...recentProjects,
  ...recentProjects,
  ...recentProjects,
  ...recentProjects,
  ...recentProjects,
  ...recentProjects,
]
