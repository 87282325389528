import { criteriaData } from "@/data/criteria"
import { useDocumentContext } from "@/components/left/DocumentContext"

interface CriteriaProps {
  // unused
  handleChosenCriteria: (criteria: string) => void
}

export default function Criteria({ handleChosenCriteria }: CriteriaProps) {
  const { setSemanticSearchClass } = useDocumentContext()

  return (
    <div className="pt-0">
      <h3 className="pb-4 text-18 font-400 text-center">
        Select from semantic criteria
      </h3>
      <div className="grid grid-cols-2 gap-2">
        {criteriaData.map((data, idx) => (
          <button
            key={idx}
            onClick={() => {
              const cls_ = data.toLowerCase().replaceAll(" ", "_")
              setSemanticSearchClass(cls_)
            }}
            className="bg-blue-100 hover:bg-blue-50 text-blue-200 font-400 text-14 py-4 px-6 rounded-12"
          >
            {data}
          </button>
        ))}
      </div>
    </div>
  )
}
