interface ButtonProps {
  children: React.ReactNode
  type?: "submit" | "reset" | "button"
  onClick?: () => void
  className?: string
}

export default function Button({
  children,
  onClick,
  type = "button",
  className = "",
}: ButtonProps) {
  return (
    <button className={className} onClick={onClick} type={type}>
      {children}
    </button>
  )
}
