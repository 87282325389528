import parse, { domToReact } from "html-react-parser"
import { Icon } from "@/assets"
import { useView } from "@/context/ViewContext"
import { useEffect, useMemo, useState } from "react"
import unidecode from "unidecode"
import { getColor, useDocumentContext } from "@/components/left/DocumentContext"
import { getIntersection } from "@/utils/keywords"

const PlaceholderText = () => {
  return (
    <div className="bg-white h-[896px] flex-auto rounded-16 flex items-center justify-center overflow-y-scroll">
      <div className="h-full flex flex-col pt-[23px] pl-[30px] pr-[22px] text-text-dark font-inter">
        <h3 className="font-500 text-22 leading-[44.16px]">
          Non-Disclosure Agreement
        </h3>
        <span className="pb-[23.8px] font-400 text-14 leading-[25.6px] text-text-light">
          Date: 2015  Parties:
        </span>
        <div className="font-400 text-16 leading-7 flex flex-col items-center">
          Ipsum non nunc nisi felis. Sed amet ut nullam iaculis vestibulum. Et
          neque pellentesque nulla non quis duis volutpat diam. Hac egestas
          convallis etiam ut. Arcu ornare a facilisi tellus lorem id elementum.
          Eget nunc mollis eu aenean. Volutpat facilisis quam pharetra
          ullamcorper nec vel scelerisque at donec. Malesuada sollicitudin morbi
          non ultrices vitae. At lectus quis pharetra tristique lacus aliquam
          netus turpis. Tortor est nibh erat augue eu eget vel mauris in. Vel
          nisl id scelerisque congue nunc senectus facilisis convallis nibh. Mi
          non eget neque porttitor imperdiet gravida vel luctus. <br />
          Fames laoreet amet nullam sagittis. Enim sapien sed ut lorem erat
          turpis mus amet dolor. Sed ut id id pharetra. Pulvinar enim dui leo
          gravida. Gravida non est non lacus. Ut risus scelerisque velit eu
          orci. Orci mi egestas proin orci. Urna tempus id dictumst nibh eu a
          massa. Est hac morbi ipsum commodo. Nullam lectus non nulla amet
          praesent rhoncus pellentesque vel tempor. Lobortis dui maecenas amet
          vehicula suspendisse. Risus gravida bibendum mattis tellus velit.{" "}
          <br /> <br />
          <div
            className="bg-background-lightBlue rounded-6 pl-5 pr-3"
            style={{ width: "calc(100% + 32px)" }}
          >
            Vitae suspendisse dui odio nulla pretium non erat pellentesque urna.
            Vel pellentesque euismod rhoncus leo purus. Eget mauris{" "}
            <span className="bg-background-blue rounded text-blue-200 font-600 p-[3px]">
              o disclose information (the Confidential Info
            </span>{" "}
            turpis donec scelerisque sit ultrices. Morbi id rutrum at volutpat.
            Vitae sem purus mollis adipiscing nisi. Leo et vel scelerisque
            tristique. Feugiat nunc tellus a tristique massa. Aliquam adipiscing
            scelerisque curabitur velit. Ac porttitor lectus vitae erat cras
            lectus et et. Ullamcorper tellus facilisis pretium lacus. Libero
            dolor maecenas tellus laoreet in facilisis. Gravida imperdiet
            facilisis amet velit neque neque sed molestie viverra.
          </div>
          <br />
          Rhoncus lacus dolor nec eget phasellus ultrices. Molestie arcu feugiat
          erat feugiat pellentesque feugiat faucibus. Sodales nunc nibh ultrices
          in euismod erat dignissim odio cursus. Pharetra pellentesque quam quis
          eget in. Cras imperdiet a odio rhoncus neque sociis lectus aenean
          fermentum. Nec est scelerisque vulputate volutpat. Commodo integer
          nisi tellus aliquet dictum viverra eget. Imperdiet tortor amet ut mi
          vitae cursus mattis sollicitudin potenti. Erat platea tristique nisi
          et nunc. Interdum lectus eu vivamus mauris in. In fames faucibus a
          lectus arcu vitae sit et. Lacus a donec pellentesque porttitor nam
          laoreet sapien. Adipiscing elementum orci ornare enim auctor. Etiam
          proin congue eget erat tempus. Erat eget etiam urna vulputate rutrum
          ultrices. Accumsan ultrices venenatis tristique tempus mauris. Odio
          consectetur diam malesuada tincidunt sed quisque viverra. Non velit
          morbi odio ullamcorper ullamcorper hendrerit faucibus duis tortor.
          Pellentesque sit egestas dui purus nulla vitae mattis. Massa id
          maecenas massa vitae sapien. Tempor eleifend vel in vel blandit
          vehicula ornare. Id auctor ultrices amet eu accumsan nibh magna. Nunc
          eget at nunc nullam arcu purus tristique in fringilla. Diam ligula
          elit mattis et vivamus. Massa aenean viverra faucibus mauris consequat
          nec. Morbi et erat velit ac hendrerit. Aliquam bibendum sagittis id
          enim turpis. Ipsum ante bibendum lorem viverra. Porttitor pulvinar
          aliquet ultrices pharetra pellentesque nunc semper ac quis. Interdum
          scelerisque at mauris.
        </div>
      </div>
    </div>
  )
}

function ViewControl({ toggleView }: { toggleView: () => void }) {
  return (
    <div className="flex gap-6 w-full justify-end pt-[11px] pb-[13px]">
      <button className="flex items-center justify-between font-500 text-15 leading-4 gap-1 text-blue-200">
        <Icon name={"SingleView"} />
        Single View
      </button>
      <button
        onClick={toggleView}
        className="flex items-center justify-between font-500 text-15 leading-4 gap-1 text-gray-400"
      >
        <Icon name={"SplitView"} />
        Split View
      </button>
    </div>
  )
}

interface EditorProps {
  keywords: string[]
}

export default function ReadOnlyEditor({
  keywords,
  setMatches,
  activeMatch,
}: EditorProps) {
  const { splitView, toggleView } = useView()
  const [isClient, setIsClient] = useState(false)
  const { htmlFile, semanticMatches } = useDocumentContext()

  useEffect(() => {
    setIsClient(true)
  }, [])

  const [cssStyle, setCssStyle] = useState("")

  // TODO: implement this in a better way!!!
  let matches_ = [] // temporary variable, only used inside search fn
  let nodeIdx

  const keywordsPrepro = useMemo(() => {
    return keywords
      .map((keyword) => {
        return keyword.replaceAll(/\s+/g, " ").trim().toLowerCase()
      })
      .filter((x) => x.length > 0)
  }, [keywords])

  function accumulateText(domNode: Element) {
    // Array<Element|Text>
    return (domNode.children as Array<any>).reduce((acc, value) => {
      if (value.type == "text") {
        // don't trim() here yet: it would merge 2 words together sometimes
        return acc + value.data
      } else if (value.children.length > 0) {
        return acc + accumulateText(value)
      } else {
        return acc
      }
    }, "")
  }

  // This function highlights the part of line that we exactly matched,
  // in addition to highlighting the entire line which the caller did.
  function highlightSpecificString(domNode) {
    if (domNode.type === "text" && domNode.data) {
      const textPrepro = domNode.data.toLowerCase()
      const { singleWordsIntersection, multiWordsIntersection } =
        getIntersection(textPrepro, keywordsPrepro)
      const keywordsToFind = [
        ...multiWordsIntersection,
        ...singleWordsIntersection,
      ]

      for (let i = 0; i < keywordsToFind.length; i++) {
        const kw = keywordsToFind.at(i)
        // Search string is preprocessed, we need to also preprocess text
        const startPos = textPrepro.search(kw)
        if (startPos >= 0) {
          const endPos = startPos + kw.length
          // TODO: preserve styles?!
          const textBefore = domNode.data.substring(0, startPos)
          const textMatch = domNode.data.substring(startPos, endPos)
          const textAfter = domNode.data.substring(endPos)
          return (
            <span>
              {textBefore}
              <span style={{ backgroundColor: "#ACC8F8" }}>{textMatch}</span>
              {textAfter}
            </span>
          )
        }
      }
    }
  }

  function searchAndHighlightExtended(domNode) {
    const lineCanBe = new Set(["li", "p"])
    for (let i = 1; i <= 6; i++) {
      lineCanBe.add(`h${i}`)
    }
    // well, each "clause" contains multiple spans -> it's always li, p, h1, ...
    if (lineCanBe.has(domNode.name)) {
      nodeIdx += 1
      // here, accumulate all text in this node into a string
      const text = accumulateText(domNode).replaceAll(/\s+/g, " ").trim()
      const textPrepro = text.toLowerCase()

      if (textPrepro.length > 0) {
        // now this function has to return not-null, so that nodeIdx is the same as in DocumentParser.tsx

        const { hasIntersection } = getIntersection(textPrepro, keywordsPrepro)

        // Semantic search
        const match = semanticMatches.find((x) => x.index === nodeIdx)
        if (match) {
          const bgColor = getColor(match.value, activeMatch === nodeIdx)
          // EBF9EB green, F9F6EB orange, FDF4F4 red

          let class_ = domNode.attribs.class ?? ""
          domNode.attribs.style += `; background-color: ${bgColor}; scroll-margin-top: 50px;`
          // yes, this is a bit of a hack i admit
          class_ += ` semantic-match-${match.index} doc-node`
          domNode.attribs.class = class_ // + (isActiveMatch ? " active-match" : "")
          return domToReact([domNode], { replace: highlightSpecificString })
        }

        // Keyword search
        else if (hasIntersection) {
          let class_ = domNode.attribs.class ?? ""
          const isActiveMatch =
            activeMatch != null && matches_.length === activeMatch
          if (isActiveMatch) {
            domNode.attribs.style +=
              "; background-color: #D6E4FC; scroll-margin-top: 50px;"
            // domNode.attribs.id = "active-match"
          } else {
            domNode.attribs.style +=
              "; background-color: #D6E4FC77; scroll-margin-top: 50px;"
          }
          // yes, this is a bit of a hack i admit
          class_ += ` match-${matches_.length} doc-node`
          domNode.attribs.class = class_ // + (isActiveMatch ? " active-match" : "")

          matches_.push(text)
          const reactNode = domToReact([domNode], {
            replace: highlightSpecificString,
          })
          // return <div style={{ backgroundColor: "yellow" }}>{reactNode}</div>
          return reactNode
        }

        return domToReact([domNode])
      }
    }
  }

  const replaceFunctionBody = searchAndHighlightExtended

  function replaceFunctionToplevel(domNode) {
    if (domNode.name === "html") {
      const children = domNode.children
      const domChildren = domToReact(children, {
        replace: replaceFunctionToplevel,
      })
      return <div>{domChildren}</div>
    }
    if (domNode.name === "body") {
      domNode.name = "div"
      const children = domNode.children
      const domChildren = domToReact([domNode], {
        replace: replaceFunctionBody,
      })
      return <div>{domChildren}</div>
    }
    if (domNode.name === "head") {
      const domChildren = domToReact(domNode.children, {
        replace: function (childNode) {
          if (childNode.type === "style") {
            setCssStyle((childNode.children[0] as Text).data)
            // return <div>{domToReact([childNode])}</div>
          }
          return <></>
        },
      })
      return <></>
    }
  }

  const options = {
    replace: replaceFunctionToplevel,
  }
  const parsed = useMemo(() => {
    if (!htmlFile) return
    matches_ = []
    nodeIdx = -1
    const reactTree = parse(htmlFile, options)
    console.log(`In B: ${nodeIdx}`)
    setMatches(matches_)
    return reactTree
  }, [keywordsPrepro, setMatches, activeMatch, htmlFile, semanticMatches])
  // ^ keywords must be absolutely there, otherwise it will trigger an infinite loop

  return (
    <div className={`py-4 pr-4 max-h-screen w-1/2 overflow-y-scroll`}>
      <div
        className={` ${
          splitView ? "p-8" : "px-4 pb-4"
        } bg-bgSecondary rounded-10 flex flex-col`}
      >
        {!splitView && <ViewControl toggleView={toggleView} />}
        {/*<PlaceholderText />*/}
        {/* CSS attribute is enabled by next.config.mjs -> styledComponents: true */}
        <div className="bg-white p-4">
          {isClient ? (
            <div className="doc-preview" css={cssStyle}>
              {parsed}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  )
}
