import { useGetActivityQuery } from "@/api/resources"
import Documents from "@/components/Documents"

export default function RecentDocuments() {
  const { data = [] } = useGetActivityQuery()
  const docs = data.filter((x) => x.resource_type === "document")

  return (
    <Documents
      title="Recent Documents"
      documents={docs}
      emptyMessage="You have no recent documents..."
    />
  )
}
