import { useCallback, useEffect, useRef, useState } from "react"
import { type LexicalEditor, type EditorState } from "lexical"

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"

import SaveStatusButton, { type SaveStatus } from "./SaveStatusButton"

export default function SavePlugin({
  autoSaveDelay = 3000, // in milliseconds
  onSave,
  onPopulate,
  saveOnExit = false,
}: {
  autoSaveDelay?: number
  onSave?: (prevState: string, currentState: string) => void | Promise<void>
  onPopulate?: (editor: LexicalEditor) => boolean | Promise<boolean>
  saveOnExit?: boolean
}) {
  const [editor] = useLexicalComposerContext()
  const [savingStatus, setSavingStatus] = useState<SaveStatus>("unchanged")
  const [editorState, setEditorState] = useState<string>()
  const [prevEditorState, setPrevEditorState] = useState<string>()

  const cachedEditorState = useRef<string>()
  const debounceTimeout = useRef<NodeJS.Timeout>()

  const saveState = useCallback(async () => {
    if (!onSave) return

    if (editorState === prevEditorState) {
      console.log("No changes")
      return
    }

    console.log("Saving Editor State", editorState)
    setSavingStatus("pending")
    const editorStateSnapshot = editorState

    try {
      await onSave(prevEditorState || "", editorStateSnapshot || "")

      console.log("Saved Editor State")
      setSavingStatus("success")
    } catch (error) {
      console.error("Error saving Editor State", error)
      setSavingStatus("failed")
    } finally {
      setPrevEditorState(editorStateSnapshot)
    }
  }, [setSavingStatus, editorState, prevEditorState])

  const onEditorChange = useCallback(
    (editorState: EditorState) => {
      const jsonState = JSON.stringify(editorState.toJSON())
      setEditorState(() => jsonState)

      setSavingStatus(() =>
        prevEditorState === jsonState ? "unchanged" : "unsaved",
      )
    },
    [setEditorState, prevEditorState, setSavingStatus],
  )

  useEffect(() => {
    async function fetchEditorState() {
      if (!onPopulate) return

      try {
        const isCached = await onPopulate(editor)
        const serializedState = JSON.stringify(editor.getEditorState().toJSON())

        setEditorState(serializedState)

        if (isCached) {
          setPrevEditorState(serializedState)
          setSavingStatus("unchanged")
        }
      } catch (error) {
        console.error("Error fetching Editor State", error)
      }
    }

    fetchEditorState()

    function saveStateOnUnload() {
      if (cachedEditorState.current && onSave) {
        onSave("", cachedEditorState.current)
      }
    }

    if (saveOnExit) {
      window.addEventListener("beforeunload", saveStateOnUnload)
    }

    return () => {
      clearTimeout(debounceTimeout.current)

      if (saveOnExit) {
        window.removeEventListener("beforeunload", saveStateOnUnload)
        saveStateOnUnload()
      }
    }
  }, [saveOnExit])

  useEffect(() => {
    // workaround for react to ensure that onUnload save state
    // is called only once on unmount, but can still see fresh state
    cachedEditorState.current = editorState
  }, [editorState])

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === "s" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault()
        saveState()
      }

      // debounce auto-save
      clearTimeout(debounceTimeout.current)
      debounceTimeout.current = setTimeout(saveState, autoSaveDelay)
    }

    return editor.registerRootListener(
      (
        rootElement: null | HTMLElement,
        prevRootElement: null | HTMLElement,
      ) => {
        if (prevRootElement !== null) {
          prevRootElement.removeEventListener("keydown", onKeyDown)
        }

        if (rootElement !== null) {
          rootElement.addEventListener("keydown", onKeyDown)
        }
      },
    )
  }, [editor, saveState])

  return (
    <>
      <SaveStatusButton
        status={savingStatus}
        disabled={
          savingStatus === "unchanged" ||
          savingStatus === "pending" ||
          savingStatus === "success"
        }
        onClick={saveState}
      />
      <OnChangePlugin onChange={onEditorChange} />
    </>
  )
}
