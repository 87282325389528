import { API_URL } from "@/config"

import { Icon } from "@/assets"
import { Modal, Status } from "@/elements"
import { useEffect, useState } from "react"
import MyLibrary from "../myLibrary"
import { StatusEnum, STATUS_DROPDOWN_VALUES } from "@/types/status"
import { DropdownValue } from "@/elements/status"
import { Button } from "@/components/ui/button"
import { useAppSelector } from "@/redux/hooks"
import { useDocumentContext } from "@/components/left/DocumentContext"
import { useSetDocumentStatusMutation } from "@/api/resources"
import { findStatus } from "@/utils/status"
import { useNavigate } from "react-router"

// unused
// const Divider = <div className="h-[19px] border-l border-gray-300"></div>

export default function DocumentHeader({ onSavedSearchSelected }) {
  const navigate = useNavigate()
  const [openLibrary, setOpenLibrary] = useState<boolean>(false)
  const closeLibraryModal = () => setOpenLibrary(false)
  const { documentId, document: documentMeta } = useDocumentContext()

  const [status, setStatus] = useState<DropdownValue | null>(null)
  const [setDocumentStatus, result] = useSetDocumentStatusMutation()

  useEffect(() => {
    // TODO: we just want to do it once on load though?
    if (documentMeta && documentMeta.status) {
      setStatus(findStatus(documentMeta.status))
    }
  }, [documentMeta])

  const accessToken = useAppSelector((state) => state.auth.accessToken)

  const onDownload = async () => {
    const url = `${API_URL}/documents/${documentId}`
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/octet-stream",
      },
    }
    // another option would be to have the backend generate a download link
    const response = await fetch(url, options)
    // Extract filename from the header
    const filenameHeader = response.headers.get("Content-Disposition")
    const filename = filenameHeader.replace(/.*filename="(.*)".*/, "$1")
    // Get file content and make the browser save it
    const blob = await response.blob()
    const fileURL = window.URL.createObjectURL(blob)
    let aLink = document.createElement("a")
    aLink.href = fileURL
    aLink.download = filename
    aLink.click()
    aLink.remove()
  }

  // TODO: make these into context !!!
  const onSelectOption = (x) => {
    onSavedSearchSelected(x)
    setOpenLibrary(false)
  }

  const setStatusAndUpdate = (newStatus: DropdownValue) => {
    setStatus(newStatus)
    setDocumentStatus({ document_id: documentId, status: newStatus.id })
  }

  return (
    <>
      <Modal isOpen={openLibrary} setOpen={setOpenLibrary}>
        <MyLibrary toggleModal={closeLibraryModal} onSelect={onSelectOption} />
      </Modal>

      <div className="flex justify-between">
        <button
          onClick={() => {
            navigate(-1)
          }}
          className="flex items-center gap-[1.5px] font-500 text-15 leading-[18.9px] text-gray-400"
        >
          <Icon name="BackArrow" />
          Back To Project
        </button>

        <div className="flex items-center">
          <div className="px-3.5 font-500 text-15 leading-[18.9px] text-gray-500 flex gap-1.5 justify-between items-center">
            <span className="px-1">Status:</span>
            <Status
              values={STATUS_DROPDOWN_VALUES}
              selectedValue={status}
              setSelectedValue={setStatusAndUpdate}
            />
          </div>

          {/*<div className="h-[19px] border-l border-gray-300"></div>*/}
          {/*<button onClick={() => setOpenLibrary(true)} className="px-3.5">*/}
          {/*  <Icon name="LibraryBlueBig" />*/}
          {/*</button>*/}

          <Button variant="ghost" size="icon" onClick={onDownload}>
            <Icon name="Export" />
          </Button>
        </div>
      </div>
    </>
  )
}
