import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"

export type SaveStatus =
  | "unchanged"
  | "unsaved"
  | "pending"
  | "success"
  | "failed"

export default function StatusButton({
  status,
  onClick,
  disabled = false,
}: {
  status: SaveStatus
  onClick?: () => void
  disabled?: boolean
}) {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      variant="ghost"
      size="sm"
      className={cn(
        "gap-1 p-0 font-normal hover:underline hover:bg-transparent dark:hover:bg-transparent hover:text-black dark:hover:text-black justify-end",
        status === "unchanged" && "invisible",
        status === "pending" && "animate-pulse disabled:opacity-100",
        status === "success" && "text-green-600 disabled:opacity-100",
        status === "failed" &&
          "text-red-600 hover:text-red-600 dark:hover:text-red-600",
      )}
    >
      {status === "unchanged" && "No Changes"}
      {status === "unsaved" && "Save"}
      {status === "pending" && (
        <>
          Saving...
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="animate-spin"
          >
            <path d="M21 12a9 9 0 1 1-6.219-8.56" />
          </svg>
        </>
      )}
      {status === "success" && (
        <>
          Saved
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
            <path d="m9 12 2 2 4-4" />
          </svg>
        </>
      )}
      {status === "failed" && (
        <>
          Save Failed
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
            <line x1="15" x2="9" y1="9" y2="15" />
            <line x1="9" x2="15" y1="9" y2="15" />
          </svg>
        </>
      )}
    </Button>
  )
}
