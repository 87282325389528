"use client"
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"

import { Project } from "@/types/project"
import { allProjects } from "@/mock/data/projects"

type ProjectContextType = {
  currentProject: Project | null
  handleCurrentProject: (project: Project) => void
}

const ProjectContext = createContext<ProjectContextType | undefined>(undefined)

export const ProjectProvider = ({ children }: { children: ReactNode }) => {
  const [currentProject, setCurrentProject] = useState<Project | null>(null)

  const handleCurrentProject = (project: Project) => setCurrentProject(project)

  useEffect(() => {
    setCurrentProject(allProjects[0])
  }, [])

  return (
    <ProjectContext.Provider value={{ currentProject, handleCurrentProject }}>
      {children}
    </ProjectContext.Provider>
  )
}

export const useProject = () => {
  const context = useContext(ProjectContext)
  if (context === undefined) {
    throw new Error("useProject must be used within a ProjectProvider")
  }
  return context
}
