import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import {
  useDeleteProjectMutation,
  useDeleteSavedSearchMutation,
} from "@/api/resources"

export function DeleteSearchModal({ open, onOpenChange, searchId }) {
  const [deleteSearch, { isLoading: isDeleting }] =
    useDeleteSavedSearchMutation()

  const doDelete = () => {
    deleteSearch(searchId)
  }

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      {/*<AlertDialogTrigger asChild>*/}
      {/*  <Button variant="outline">Show Dialog</Button>*/}
      {/*</AlertDialogTrigger>*/}
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete the saved
            search.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            variant="destructive"
            onClick={doDelete}
            disabled={isDeleting}
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
