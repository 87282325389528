import { Tabs, useFilterContext } from "@/components/Project/project/Filtering";
import { KeywordsSearch } from "@/components/Project/project/main/keywordsTab";
import { SemanticDropdown } from "@/components/Project/project/main/semanticTab";


export default function ProjectsSearch() {
  const {activeTab, setActiveTab} = useFilterContext()

  const handleTabClick = (tab: Tabs) => {
    setActiveTab(tab)
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4">
        <div
          onClick={() => handleTabClick("keywords")}
          className={`font-500 text-15 border-b text-black ${
            activeTab === "keywords"
              ? " border-blue-200"
              : "border-transparent opacity-20"
          }`}
        >
          Keywords
        </div>
      </div>

      <KeywordsSearch />
      <SemanticDropdown />
    </div>
  )
}
