import { useGetActivityQuery } from "@/api/resources"
import Projects from "@/components/projects"

export default function RecentProjects() {
  const { data = [] } = useGetActivityQuery()
  const recentProjects = data.filter((x) => x.resource_type === "project")

  if (recentProjects.length === 0) {
    return (
      <p className="text-gray text-center">You have no recent projects...</p>
    )
  }

  return <Projects projects={recentProjects} />
}
