export function getIntersection(text: string, keywordsPrepro: string[]) {
  // we don't unidecode
  let textPrepro = text.replaceAll(/\s+/g, " ").trim().toLowerCase()
  const textTokens = textPrepro.split(" ")
  // console.log(textPrepro)
  const singleKeywords = keywordsPrepro.filter((x) => x.search(" ") === -1)
  const multiWordKeywords = keywordsPrepro.filter((x) => x.search(" ") !== -1)

  // we need to handle 1 word and multiple words differently
  const singleWordsIntersection = singleKeywords.filter((x) =>
    textTokens.some((y) => y.startsWith(x)),
  )
  const multiWordsIntersection = multiWordKeywords.filter(
    (x) => textPrepro.search(x) !== -1,
  )

  const hasIntersection =
    singleWordsIntersection.length > 0 || multiWordsIntersection.length > 0

  return { hasIntersection, singleWordsIntersection, multiWordsIntersection }
}
