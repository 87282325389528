import { Icon } from "@/assets"
import { Link } from "react-router-dom"
import { useProjectContext } from "@/components/Project/ProjectContext"
import { DirectorySchema, useGetDirectoryPathQuery } from "@/api/resources"
import { useMemo } from "react"

interface CurrentPathProps {
  folder?: DirectorySchema
}
export default function CurrentPath({ folder }: CurrentPathProps) {
  const project = useProjectContext()
  const LINK_STYLE = "flex gap-2 items-center hover:text-slate-900"

  const {
    data: pathElements,
    isError,
    isLoading,
  } = useGetDirectoryPathQuery(folder?.directory_id, { skip: !folder })

  const path = useMemo(() => {
    // TODO: the endpoint returns 404 when it's not a subdirectory, then old data is used
    // more generally, when isLoading -> don't show old data! (still flickers)
    if (isError || isLoading) {
      return []
    } else {
      return pathElements ?? []
    }
  }, [pathElements, isError, isLoading])

  return (
    <div className="font-500 text-14 leading-6 text-gray-400 flex gap-2 items-center">
      <Link className={LINK_STYLE} to="/projects">
        Projects
        <Icon name="Chevron" />
      </Link>
      <Link
        className={`${project ? LINK_STYLE : "hidden"}`}
        to={`/projects/${project?.project_id}`}
      >
        {project?.display_name}
        <Icon name="Chevron" />
      </Link>
      {path.length > 0 &&
        path.map((f, ix) => (
          <Link
            className={LINK_STYLE}
            to={`/folders/${f.directory_id}`}
            key={ix}
          >
            {f.display_name}
            <Icon name="Chevron" />
          </Link>
        ))}
      <span className="hover:text-slate-900">{folder?.display_name}</span>
    </div>
  )
}
