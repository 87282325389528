import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

interface CustomDropdownProps {
  items: string[]
  placeholder: string
  value: string
  onChange: (value: string) => void
}

// a wrapper of shadcn dropdown with boilerplace code included
export default function ControlledDropdown({ items, placeholder, onChange, value }: CustomDropdownProps) {
  return (
    <Select
      onValueChange={onChange}
      value={value}
    >
      <SelectTrigger>
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent>
        {items.map((item) => (
          <SelectItem key={item} value={item}>
            {item}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}