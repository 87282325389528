import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Icon } from "@/assets"
import { ReloadIcon } from "@radix-ui/react-icons"
import {
  useCreateDirectoryMutation,
  useRenameDirectoryMutation,
} from "@/api/resources"
import { toast } from "sonner"

const createFolderSchema = z.object({
  folder_name: z.string().min(2).max(255),
})

export interface Params {
  projectId: string
  action: "create" | "rename"
  parentId?: string | undefined // if creating - parent directory
  folderId?: string | undefined // if renaming
  initialName?: string | null // if renaming
  open: boolean
  onOpenChange: (boolean) => void
  showButton?: boolean
}

const renderForm = ({ field }) => {
  return (
    <FormItem>
      <FormLabel>Folder name</FormLabel>
      <FormControl>
        <Input type="text" placeholder="Enter name of the folder" {...field} />
      </FormControl>
      <FormDescription>
        The folder name should have between 2 and 255 characters.
      </FormDescription>
    </FormItem>
  )
}

export const CreateOrRenameFolderDialog = ({
  initialName,
  action,
  projectId,
  parentId,
  folderId,
  open,
  onOpenChange,
  showButton,
}: Params) => {
  const [createDirectory, { isLoading: isCreating }] =
    useCreateDirectoryMutation()
  const [renameDirectory, { isLoading: isRenaming }] =
    useRenameDirectoryMutation()

  if (action === "create" && folderId) {
    throw Error("Create can't be used with folderId. Use parentId instead")
  }

  const isLoading = isCreating | isRenaming

  const form = useForm<z.infer<typeof createFolderSchema>>({
    resolver: zodResolver(createFolderSchema),
    values: {
      folder_name: initialName ?? "",
    },
  })

  async function onSubmit(values: z.infer<typeof createFolderSchema>) {
    if (action === "create") {
      const toastLoadingId = toast.loading("Creating folder...")

      const result = await createDirectory({
        project_id: projectId,
        display_name: values.folder_name,
        parent_id: parentId,
      })

      if (result.error && (result.error as any).data) {
        toast.error((result.error as any).data.detail, {
          id: toastLoadingId,
        })
      } else {
        toast.success("Folder created successfully.", {
          id: toastLoadingId,
        })

        form.reset()
        onOpenChange(false)
      }
    } else {
      const toastLoadingId = toast.loading("Renaming folder...")

      const result = await renameDirectory({
        directory_id: folderId,
        display_name: values.folder_name,
      })

      if (result.error && (result.error as any).data) {
        toast.error((result.error as any).data.detail, {
          id: toastLoadingId,
        })
      } else {
        toast.success("Folder renamed successfully.", {
          id: toastLoadingId,
        })

        form.reset()
        onOpenChange(false)
      }
    }
  }

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        {showButton && (
          <DialogTrigger asChild>
            <Button
              variant="ghost"
              className="flex items-center text-blue-200 font-500 text-16 gap-1.5"
            >
              <Icon name="Plus" />{" "}
              <span className="text-blue-200 text-15">Create Folder</span>
            </Button>
          </DialogTrigger>
        )}
        <DialogContent>
          <DialogHeader>
            <h3 className="text-22 text-black text-center">
              {action === "create" ? "Create Folder" : "Rename Folder"}
            </h3>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="folder_name"
                render={renderForm}
              />
              <Button
                type="reset"
                variant="secondary"
                onClick={() => {
                  form.reset()
                  onOpenChange(false)
                }}
              >
                Cancel
              </Button>
              <Button className="ml-2" type="submit">
                {isLoading ? (
                  <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  ""
                )}
                {action === "create" ? "Create" : "Rename"}
              </Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  )
}
