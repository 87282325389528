import { type ReactNode, useState } from "react"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { UploadFile } from "@/components/UploadFile"
import { toast } from "sonner"
import { useUpdateUserPhotoMutation } from "@/api/resources"

export default function UpdateAvatarModal({ trigger }: { trigger: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false)
  const [updatePhoto] = useUpdateUserPhotoMutation()

  async function handleFileChange(files: File[] | null) {
    if (files) {
      const loadingToastId = toast.loading("Uploading your profile picture...")

      const [file] = files
      const MAX_SIZE = 10 * 1024 * 1024 // 10 MB

      if (file.size >= MAX_SIZE) {
        toast.error("Uploaded file is too large.", {
          id: loadingToastId,
          description: "The file size should not exceed 1 MB.",
        })

        return
      }

      const formData = new FormData()
      formData.append("payload", file)

      const result = await updatePhoto(formData)
      if (!result.error) {
        toast.success("Profile picture uploaded successfully.", {
          id: loadingToastId,
        })
        setIsOpen(false)

        return
      }

      if (
        "data" in result.error &&
        "detail" in (result.error.data as { detail: string })
      ) {
        const { detail } = result.error.data as { detail: string }

        if (Array.isArray(detail)) {
          toast.error("Failed to upload profile picture.", {
            description: detail[0].msg.replace("Value error, ", ""),
            id: loadingToastId,
          })
        } else {
          toast.error("Failed to upload profile picture.", {
            description: detail,
            id: loadingToastId,
          })
        }
      } else {
        toast.error("Failed to upload profile picture.", {
          id: loadingToastId,
        })
      }
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Update profile picture</DialogTitle>
          <DialogDescription>
            You can update your profile picture here. Select a picture from your
            computer or drag and drop it below.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <UploadFile
            sizeDesc="1 MB"
            itemDesc="a picture"
            onUpload={handleFileChange}
            dashed
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}
