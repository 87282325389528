import { useCallback, useRef } from "react"
import axios from "axios"

import { type LexicalEditor, $getRoot, $insertNodes } from "lexical"
import { $generateNodesFromDOM } from "@lexical/html"
import { HeadingNode, QuoteNode } from "@lexical/rich-text"
import { CodeNode } from "@lexical/code"
import { LinkNode } from "@lexical/link"
import { ListNode, ListItemNode } from "@lexical/list"
import { TRANSFORMERS as MARKDOWN_TRANSFORMERS } from "@lexical/markdown"

import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary"
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode"

import { Separator } from "@/components/ui/separator"
import { useDocumentContext } from "@/components/left/DocumentContext"

import ToolbarPlugin from "./ToolbarPlugin"
import SavePlugin from "./SavePlugin"
import { API_URL } from "@/config"
import {
  useGetEditorStateQuery,
  useSaveEditorStateMutation,
} from "@/api/resources"

const config = {
  namespace: "ReadWriteEditor",
  nodes: [
    // Required nodes for Markdown plugin
    HorizontalRuleNode,
    CodeNode,
    LinkNode,
    ListNode,
    ListItemNode,
    HeadingNode,
    QuoteNode,
  ],
  theme: {
    text: {
      bold: "font-bold",
      italic: "italic",
      underline: "underline",
    },
  },
  onError: (error: Error) => {
    console.error(error)
  },
}

export default function ReadWriteEditor() {
  const { htmlFile, documentId } = useDocumentContext()
  const { data: initialState, isLoading } = useGetEditorStateQuery(documentId)
  const [saveEditorState] = useSaveEditorStateMutation()

  const handleSave = useCallback(
    async (_: string, currentState: string) => {
      const result = await saveEditorState({
        document_id: documentId,
        editor_state: currentState,
      })

      if (result.error) {
        throw new Error("Failed to save document")
      }
    },
    [documentId],
  )

  const handlePopulate = useCallback(
    async (editor: LexicalEditor) => {
      editor.update(() => {
        if (initialState) {
          const editorState = initialState.editor_state
          const parsedState = editor.parseEditorState(editorState)

          editor.setEditorState(parsedState)
        } else {
          // fallback to HTML content, if there is no saved editor state
          $getRoot()
            .getChildren()
            .forEach((node) => node.remove())

          // if ($getRoot().isEmpty()) {
          //   const parser = new DOMParser()
          //   const htmlDom = parser.parseFromString(htmlFile, "text/html")
          //
          //   const nodes = $generateNodesFromDOM(editor, htmlDom)
          //   console.log(nodes)
          //   $getRoot().select()
          //
          //   $insertNodes(nodes)
          // }
        }
      })

      return !!initialState
    },
    [documentId, initialState],
  )

  if (!htmlFile || !documentId || isLoading) {
    // TODO: show loader
    return null
  }

  return (
    <LexicalComposer initialConfig={config}>
      <div className="py-4 pr-4 min-h-screen w-1/2">
        <div className="p-8 bg-bgSecondary rounded-16 h-full flex flex-col overflow-hidden">
          <div className="bg-white flex-auto rounded-16 flex flex-col h-full">
            <header className="flex gap-1 items-center justify-between p-4 pb-2">
              <ToolbarPlugin className="flex-1 justify-start" />
              <SavePlugin
                autoSaveDelay={3000}
                onSave={handleSave}
                onPopulate={handlePopulate}
                saveOnExit
              />
            </header>

            <Separator className="bg-gray-300 dark:bg-gray-30 w-[97.5%] mx-auto" />

            <div className="w-full flex-1 relative overflow-y-scroll">
              <RichTextPlugin
                contentEditable={
                  <ContentEditable
                    className="w-full h-full focus:outline-none p-4 pt-6 prose min-w-full"
                    aria-placeholder="Start writing here..."
                    placeholder={
                      <div className="sr-only">Start writing here...</div>
                    }
                  />
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <MarkdownShortcutPlugin transformers={MARKDOWN_TRANSFORMERS} />
              <HistoryPlugin />
              <AutoFocusPlugin />
            </div>
          </div>
        </div>
      </div>
    </LexicalComposer>
  )
}
