import { Profile } from "@/assets"

export default function Avatar({
  name,
  photo,
  size = 32,
}: {
  name?: string | null
  photo?: string | null
  size?: number
}) {
  return (
    <div
      className="rounded-full overflow-clip"
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <img
        src={photo ? photo : Profile}
        alt={name ? name + "profile avater" : "profile"}
        className="w-full h-full object-cover"
      />
    </div>
  )
}
