import { useState } from "react"

import Keywords from "@/components/keywords"
import Search from "@/components/left/main/searchBar/search"

interface KeywordsTabProps {
  keywords: string[]
  handleKeywords: (data: string[]) => void
}

export default function KeywordsTab({
  keywords,
  handleKeywords,
}: KeywordsTabProps) {
  const [query, setQuery] = useState<string>("")

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery(e.target.value)

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      console.log("KeywordsTab searching for:", query)

      handleKeywords([...keywords, query])
      setQuery("")
    }
  }
  const deleteKeyword = (idx: number) => {
    const existingKeywords = [...keywords]
    existingKeywords.splice(idx, 1)
    handleKeywords(existingKeywords)
  }

  return (
    <div>
      <Keywords
        keywords={keywords}
        parentClass={`flex-wrap pb-4 ${
          keywords.length > 0 ? "block" : "hidden"
        }`}
        childClass={
          "rounded-6 bg-background-darkGrey text-text-darkGrey font-500 text-16 leading-4 flex gap-2 items-center w-fit p-2"
        }
        deleteKeyword={deleteKeyword}
      />

      <Search
        query={query}
        handleChange={handleChange}
        handleKeyPress={handleKeyPress}
        placeholder="Type keywords ⏎, or paste from excel"
        classname="placeholder-gray py-5 rounded-8"
        autoFocus
      />
    </div>
  )
}
