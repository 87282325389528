export const savedSearchesData = [
  {
    name: "Saved Search Name",
    keywords: ["Keyword", "Keyword"],
  },
  {
    name: "Saved Search Name",
    keywords: [
      "Keyword",
      "Keyword",
      "Keyword",
      "Keyword",
      "Keyword",
      "Keyword",
      "Keyword",
    ],
  },
  {
    name: "Saved Search Name",
    keywords: ["Keyword", "Keyword"],
  },
  {
    name: "Saved Search Name",
    keywords: ["Keyword", "Keyword", "Keyword", "Keyword"],
  },
  {
    name: "Saved Search Name",
    keywords: ["Keyword", "Keyword", "Keyword"],
  },
  {
    name: "Saved Search Name",
    keywords: [
      "Keyword",
      "Keyword",
      "Keyword",
      "Keyword",
      "Keyword",
      "Keyword",
      "Keyword",
    ],
  },
  {
    name: "Saved Search Name",
    keywords: ["Keyword", "Keyword"],
  },
]
