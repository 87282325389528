import { cva, VariantProps } from "class-variance-authority"
import { HtmlHTMLAttributes } from "react"
import { cn } from "@/lib/utils"
import { Slot } from "@radix-ui/react-slot"
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip"
import clsx from "clsx"

const pulsingIndicatorVariants = cva("w-2 h-2 rounded-full relative", {
  variants: {
    variant: {
      pending:
        "bg-blue-200/80 before:content-[''] before:w-2.5 before:h-2.5 before:animate-ping before:bg-blue-200 before:absolute before:-top-[1px] before:-left-[1px]  before:rounded-full",
      success: "bg-green-300",
      error: "bg-red-400",
    },
  },
  defaultVariants: {
    variant: "pending",
  },
})

export interface PulsingIndicatorProps
  extends HtmlHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof pulsingIndicatorVariants> {
  asChild?: boolean
  tooltips?: Partial<
    Record<
      NonNullable<VariantProps<typeof pulsingIndicatorVariants>["variant"]>,
      string
    >
  >
}

export default function PulsingIndicator({
  className,
  tooltips,
  variant,
  asChild = false,
  ...props
}: PulsingIndicatorProps) {
  const Comp = asChild ? Slot : "div"

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Comp
            className={cn(pulsingIndicatorVariants({ variant, className }))}
            {...props}
          ></Comp>
        </TooltipTrigger>
        <TooltipContent
          sideOffset={5}
          className={clsx(
            variant === "pending" && "bg-blue-200",
            variant === "success" && "bg-green-400",
            variant === "error" && "bg-red-400",
          )}
        >
          <p>{tooltips?.[variant ?? "pending"]}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
