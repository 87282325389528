import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Icon } from "@/assets"
import { useState } from "react"
import { DeleteDocumentDialog } from "@/components/Documents/document/DeleteDocumentDialog"
import { RenameDocumentDialog } from "@/components/Documents/document/RenameDocumentDialog"
import { DocumentSchema, useSetDocumentStatusMutation } from "@/api/resources"
import Status, { DropdownValue } from "@/elements/status"
import { STATUS_DROPDOWN_VALUES } from "@/types/status"
import { findStatus } from "@/utils/status"

interface Props {
  document: DocumentSchema
}
// TODO: merge with FolderDropdown if there's no difference in buttons
//
export default function DocumentDropdown({ document }: Props) {
  const ICON_STYLE = "h-[20px] mr-1"
  const [isRenamingDialog, setRenamingDialog] = useState(false)
  const [isDeletingDialog, setDeletingDialog] = useState(false)
  const initialStatus = document.status ? findStatus(document.status) : null
  const [selectedStatus, setSelectedStatus] =
    useState<DropdownValue>(initialStatus)
  const [setDocumentStatus, result] = useSetDocumentStatusMutation()

  const setStatusAndUpdate = (newStatus: DropdownValue) => {
    setSelectedStatus(newStatus)
    setDocumentStatus({
      document_id: document.document_id,
      status: newStatus.id,
    })
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className="focus:outline"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Icon name="More" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="py-2">
        <DropdownMenuLabel>Status</DropdownMenuLabel>
        <Status
          values={STATUS_DROPDOWN_VALUES}
          selectedValue={selectedStatus}
          setSelectedValue={setStatusAndUpdate}
        />
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="mb-1"
          onClick={() => {
            setRenamingDialog(true)
          }}
        >
          <Icon name="Edit" className={ICON_STYLE} />
          Rename
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            setDeletingDialog(true)
          }}
        >
          <Icon name="Delete" className={ICON_STYLE} />
          Delete
        </DropdownMenuItem>
      </DropdownMenuContent>
      {document && (
        <>
          <RenameDocumentDialog
            document={document}
            open={isRenamingDialog}
            onOpenChange={setRenamingDialog}
          />
          <DeleteDocumentDialog
            document={document}
            open={isDeletingDialog}
            onOpenChange={setDeletingDialog}
          />
        </>
      )}
    </DropdownMenu>
  )
}
