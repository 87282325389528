import { useState } from "react"

const matches = [
  "The Discloser Keyword intends to disclose information (the Confidential Info o disclose information (the Confidential Info",
  "to disclose information (the Confidential Info",
  "to disclose information (the Confidential Info",
  "to disclose information (the Confidential Info",
  "to disclose information (the Confidential Info",
  "to disclose information (the Confidential Info",
  "to disclose information (the Confidential Info",
]

const handleActiveMatchBg = (
  activeMatch: number | null,
  idx: number,
): string => {
  if (activeMatch !== null) {
    if (activeMatch === idx) return "bg-background-darkBlue"
    return "bg-blue-100 opacity-60"
  }

  return "bg-blue-100"
}

export default function MinSearchMatch() {
  const [activeMatch, setActiveMatch] = useState<number | null>(null)

  return (
    <>
      {matches.map((match, idx) => (
        <div
          key={idx}
          className={`flex items-center rounded-9 py-[37px] px-[13px]  w-[56px] h-[108.75px] text-gray font-400 text-14 leading-[17.64px] text-ellipsis whitespace-nowrap overflow-hidden ${handleActiveMatchBg(
            activeMatch,
            idx,
          )}`}
          onClick={() => setActiveMatch(idx)}
        >
          {match}
        </div>
      ))}
    </>
  )
}
