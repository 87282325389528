import { useGetUserQuery } from "@/api/resources"
import Avatar from "@/components/header/profileSettings/Avatar"

export default function Welcome() {
  const { data: userInfo } = useGetUserQuery()
  // TODO: logout if we can't get userInfo (but check it in a parent component)

  return (
    <div className="flex items-center gap-2.5">
      <Avatar name={userInfo?.first_name} photo={userInfo?.photo} />

      <div>
        <p className="text-17 leading-4 text-gray-400">
          Welcome back, {userInfo?.first_name ?? "(first_name)"}!
        </p>
        <p className="font-500 text-24 text-black">Your Recent Projects</p>
      </div>
    </div>
  )
}
