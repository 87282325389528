import { criteriaData } from "@/data/criteria"
import { Dropdown } from "@/elements"
import { useState } from "react"
import { useDocumentContext } from "../../DocumentContext"

interface DropdownProps {
  chosenCriteria: string
}

export default function CriteriaDropdown({ chosenCriteria }: DropdownProps) {
  const sentenceCaseCriteria = chosenCriteria
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")

  const [value, setValue] = useState<string | null>(sentenceCaseCriteria)
  const { setSemanticSearchClass, setActiveMatch } = useDocumentContext()

  return (
    <Dropdown
      defaultValue={sentenceCaseCriteria}
      value={value}
      onChange={(value) => {
        setValue(value)
        setActiveMatch(null)
        setSemanticSearchClass(
          value ? value.toLowerCase().replaceAll(" ", "_") : null,
        )
      }}
      options={criteriaData}
      icon="BlueDownarrow"
      currentBtnClassName="bg-bgSecondary w-full rounded-12 p-4 flex justify-between items-center font-500 text-16 text-gray-500"
      optDivClassName="absolute top-12 left-0 bg-bgSecondary rounded-b-10 flex flex-col p-4 w-full z-10"
      optBtnClassName="block hover:bg-gray-200 hover:rounded-10 py-3 px-4 w-full text-left leading-6 font-400 text-16 text-gray-500"
    />
  )
}
