import { StatusEnum } from "@/types/status"
import { findStatus } from "@/utils/status"

interface StatusProps {
  status: StatusEnum
}

export default function ProjectStatus({ status }: StatusProps) {
  const statusObj = findStatus(status)
  const color = statusObj?.color
  const statusCls = statusObj ? `status-${statusObj.id}` : ""
  return (
    <div
      className={`py-1 px-2 text-14 leading-4 w-fit rounded !border-none ${statusCls}`}
    >
      {statusObj?.name}
    </div>
  )
}
