import * as React from "react"

import { cn } from "@/lib/utils"
import { Eye, EyeOff } from "lucide-react"

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex h-9 w-full rounded-md border border-slate-200 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-800 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:placeholder:text-gray-400 dark:focus-visible:ring-slate-300 dark:text-white",
          className,
        )}
        ref={ref}
        {...props}
      />
    )
  },
)
Input.displayName = "Input"

const ToggleableInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, placeholder, ...props }, ref) => {
    const [show, setShow] = React.useState(false)

    const toggleablePlaceholder = React.useMemo(
      () =>
        show
          ? placeholder
          : Array.from(
              { length: placeholder ? placeholder.length : 8 },
              () => "•",
            ).join(""),
      [placeholder, show],
    )

    return (
      <div className="relative">
        <Input
          type={show ? "text" : "password" || type}
          ref={ref}
          {...props}
          placeholder={toggleablePlaceholder}
        />

        <button
          type="button"
          onClick={() => setShow((prev) => !prev)}
          className="absolute top-1/2 right-3 -translate-y-1/2 text-slate-400 hover:cursor-pointer hover:text-slate-700 transition-colors"
        >
          {show ? <EyeOff size={18} /> : <Eye size={18} />}
        </button>
      </div>
    )
  },
)
ToggleableInput.displayName = "ToggleableInput"

export { Input, ToggleableInput }
