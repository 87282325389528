import { foldersType } from "@/types/folder"
import { recentDocuments } from "./recentDocuments"
import { PopulatedDirectorySchema, ResourceSchema } from "@/api/resources"

// TODO: completely remove foldersType

export const recentFolders: ResourceSchema[] = [
  {
    project_id: "1",
    display_name: "September Documents",
    resource_type: "directory",
    created_at: "2021-09-01T00:00:00",
    directory_id: "1",
    // totalFiles: 24,
    // doneFiles: 5,
    // documents: recentDocuments,
  },
  {
    project_id: "1",
    display_name: "October Documents",
    resource_type: "directory",
    created_at: "2021-10-01T00:00:00",
    directory_id: "2",
    // totalFiles: 24,
    // doneFiles: 5,
    // documents: recentDocuments,
  },
  {
    project_id: "1",
    display_name: "December Documents",
    resource_type: "directory",
    created_at: "2021-12-01T00:00:00",
    directory_id: "3",
    // totalFiles: 24,
    // doneFiles: 5,
    // documents: recentDocuments,
  },
  {
    project_id: "1",
    display_name: "June Documents",
    resource_type: "directory",
    created_at: "2021-06-01T00:00:00",
    directory_id: "4",
    // totalFiles: 24,
    // doneFiles: 24,
    // documents: recentDocuments,
  },
]

export const folders = [...recentFolders, ...recentFolders]

//export interface DocumentSchema {
//   document_id: string
//   project_id: string
//   display_name: string
//   created_at: string
//   updated_at: string | null
//   status: string | null
// }

export const MOCK_POPULATED_FOLDER: PopulatedDirectorySchema = {
  directory_id: "b5d1c1a4-2b5d-4e3b-8b0d-0a1d1b4d9d1d",
  project_id: "1",
  parent_id: "b5d1c1a4-2b5d-4e3b-8b0d-0a1d1b4d9d1d",
  display_name: "A very cool directory.",
  created_at: "2021-07-01T00:00:00",
  updated_at: "2021-07-01T00:00:00",
  resource_type: "directory",
  contents: [
    {
      directory_id: "b5d1c1a4-2b5d-4e3b-8b0d-0a1d1b4d9d1d",
      project_id: "1",
      parent_id: "b5d1c1a4-2b5d-4e3b-8b0d-0a1d1b4d9d1d",
      display_name: "A very cool directory.",
      created_at: "2021-07-01T00:00:00",
      updated_at: "2021-07-01T00:00:00",
      resource_type: "directory",
    },
    {
      document_id: "b5d1c1a4-2b5d-4e3b-8b0d-0a1d1b4d9d1d",
      project_id: "1",
      parent_id: "b5d1c1a4-2b5d-4e3b-8b0d-0a1d1b4d9d1d",
      display_name: "A very cool document.",
      created_at: "2021-07-01T00:00:00",
      updated_at: "2021-07-01T00:00:00",
      resource_type: "document",
    },
  ],
}
