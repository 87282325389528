import { PDFPageProxy } from "pdfjs-dist/types/display/api";


export class PDFPageRenderer {
  private currentRenderTask?: ReturnType<PDFPageProxy["render"]>;

  constructor(
    readonly page: PDFPageProxy,
    readonly canvas: HTMLCanvasElement,
    readonly onError: (e: Error) => void
  ) {
  }

  cancelCurrentRender() {
    if (this.currentRenderTask === undefined) {
      return;
    }
    this.currentRenderTask.promise.then(
      () => {
      },
      (err: any) => {
        if (err instanceof Error && err.message.indexOf("Rendering cancelled") !== -1) {
          // Swallow the error that's thrown when the render is canceled.
          return;
        }
        const e = err instanceof Error ? err : new Error(err);
        this.onError(e);
      }
    );
    this.currentRenderTask.cancel();
  }

  render(scale: number) {
    const viewport = this.page.getViewport({ scale });

    this.canvas.height = viewport.height;
    this.canvas.width = viewport.width;

    const canvasContext = this.canvas.getContext("2d");
    if (canvasContext === null) {
      throw new Error("No canvas context");
    }
    this.currentRenderTask = this.page.render({ canvasContext, viewport });
    return this.currentRenderTask;
  }

  rescaleAndRender(scale: number) {
    this.cancelCurrentRender();
    return this.render(scale);
  }
}