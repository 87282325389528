import { PropsWithChildren } from "react"
import { AppHeader } from "@/components"

export default function AppPage({ children }: PropsWithChildren) {
  return (
    <div className="w-full h-full flex flex-col items-center">
      <AppHeader />
      {children}
    </div>
  )
}
