export const criteriaData = [
  "Anti-assignment",
  "Audit rights",
  "Cap on liability",
  "Change of control",
  "Covenant not to sue",
  "Exclusivity",
  "Governing law",
  "IP ownership assignment",
  "License grant",
  "Minimum commitment",
  "No-solicit of employees",
  "Non-compete",
  "Non-disparagement",
  "Non-transferable license",
  "Post-termination services",
  "Renewal term",
  "Revenue/profit sharing",
  "Rofr/rofo/rofn",
  "Termination for convenience",
  "Uncapped liability",
  "Warranty duration",
] as const satisfies string[]
