import { Icon } from "@/assets"
import CriteriaMatch from "@/components/left/main/criteriaMatch"
import CriteriaDropdown from "@/components/left/main/criteriaDropdown"

interface SimilarityCriteriaProps {
  chosenCriteria: string
  clearSearch: () => void
}

export default function SimilarityCriteria({
  chosenCriteria,
  clearSearch,
}: SimilarityCriteriaProps) {
  return (
    <div className="mt-4">
      <button
        onClick={clearSearch}
        className="px-3 py-2 mb-3 flex center
            gap-2 bg-bgSecondary rounded-5
            text-12"
      >
        <Icon name="Close" />
        Clear search
      </button>
      <CriteriaDropdown chosenCriteria={chosenCriteria} />
      <CriteriaMatch />
    </div>
  )
}
