import { useState } from "react"
import { Trash2Icon, PencilIcon, UsersIcon, FileSpreadsheetIcon } from "lucide-react"

import { Icon } from "@/assets"
import { Button } from "@/components/ui/button"
import { DeleteProjectDialog } from "@/components/allProjects/DeleteProjectDialog"
import { CreateOrRenameProjectDialog } from "@/components/allProjects/CreateOrRenameProjectDialog"
import { ProjectSchema } from "@/api/resources/types"

interface TopProps {
  project: ProjectSchema
  toggleDropdown: () => void
  toggleShare: () => void
  onExportProject: () => void
}

const TEXT_COLOR = "#4975A1"
// const TEXT_COLOR = "#37474F" // material grey

export default function ProjectHeader({
  project,
  toggleDropdown,
  toggleShare,
  onExportProject,
}: TopProps) {
  const [isRenameModal, setRenameModal] = useState<boolean>(false)
  const [isDeleteModal, setDeleteModal] = useState<boolean>(false)

  return (
    <div className="bg-bgSecondary w-full flex justify-center">
      <div className="flex justify-between py-5 wrapper">
        <div className="flex items-center gap-2 text-24 text-black font-500">
          <Icon name="Case" />
          {project.display_name}
          <p className="bg-blue-200 text-white px-2 py-0.5 text-xs rounded-full capitalize font-semibold">
            {project.role}
          </p>
        </div>

        <div className="flex items-center text-14 leading-6">
          <Button
            variant="ghost"
            className="text-blue-200 gap-1.5"
            onClick={toggleDropdown}
          >
            <Icon name="Export" />
            Upload Document
          </Button>

          <div className="h-4 border-l border-[#B6CADE]" role="none"></div>

          <Button
            variant="ghost"
            className="gap-2.5"
            style={{ color: TEXT_COLOR }}
            onClick={onExportProject}
          >
            <FileSpreadsheetIcon className="w-[16px] h-[16px]" />
            {/*<Icon name="Case" className="h-[24px]" />*/}
            Generate Report
          </Button>

          <div className="h-4 border-l border-[#B6CADE]" role="none"></div>

          <Button
            variant="ghost"
            className="gap-2.5"
            onClick={toggleShare}
            style={{ color: TEXT_COLOR }}
            // FIXME: replace this color everywhere !!!
          >
            <UsersIcon className="w-[16px] h-[16px]" />
            {/*<Icon name="Share" className="h-[20px]" />*/}
            Permissions
          </Button>

          <div className="h-4 border-l border-[#B6CADE]" role="none"></div>

          <Button
            variant="ghost"
            className="gap-2.5"
            style={{ color: TEXT_COLOR }}
            onClick={() => {
              setRenameModal(true)
            }}
          >
            <PencilIcon className="w-[16px] h-[16px]" />
            {/*<Icon name="Edit" className="h-[20px]" />*/}
            Rename
          </Button>

          {project.role == "owner" && (
            <>
              <div className="h-4 border-l border-[#B6CADE]" role="none"></div>
              <Button
                variant="ghost"
                className="gap-2.5"
                style={{ color: "#E57373" }}
                disabled={isDeleteModal}
                onClick={() => {
                  setDeleteModal(true)
                }}
              >
                <Trash2Icon className="w-[16px] h-[16px]" />
                {/*<Icon name="Delete" className="h-[20px]" />*/}
                Delete
              </Button>
            </>
          )}
        </div>
      </div>
      <CreateOrRenameProjectDialog
        projectId={project.project_id}
        initialName={project.display_name}
        action="rename"
        open={isRenameModal}
        onOpenChange={setRenameModal}
      />
      <DeleteProjectDialog
        projectId={project.project_id}
        open={isDeleteModal}
        onOpenChange={setDeleteModal}
      />
    </div>
  )
}
