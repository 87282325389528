"use client"

import { Icon } from "@/assets"
import { IconNameEnum } from "@/types/icons"
import { useEffect, useRef, useState } from "react"

interface DropdownProps<T extends Array<any>> {
  defaultValue?: T[number] | null
  value: T[number] | null
  onChange: (value: T[number] | null) => void
  placeholder?: string
  options: T
  icon: keyof typeof IconNameEnum
  parentClassName?: string
  currentBtnClassName?: string
  optDivClassName?: string
  optBtnClassName?: string
  status?: boolean
  resetOnDestroy?: boolean
}

export default function Dropdown<T extends Array<any>>({
  defaultValue = null,
  value,
  onChange,
  placeholder,
  options,
  icon,
  parentClassName,
  currentBtnClassName,
  optBtnClassName,
  optDivClassName,
  resetOnDestroy = false,
}: DropdownProps<T>) {
  const [isOpen, setIsOpen] = useState(false)
  const dropDownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropDownRef.current &&
        !dropDownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)

      if (resetOnDestroy) {
        onChange(defaultValue)
      }
    }
  }, [])

  const handleOptionClick = (option: string) => {
    onChange(option)
    setIsOpen(false)
  }

  const toggleDropdown = () => setIsOpen((prev) => !prev)

  return (
    <div className={`relative ${parentClassName}`} ref={dropDownRef}>
      <button
        type="button"
        className={`${currentBtnClassName}`}
        onClick={toggleDropdown}
      >
        {value || placeholder}
        <Icon
          name={icon || "BlueDownarrow"}
          className={`transition-transform duration-300 ${
            isOpen ? "transform rotate-180" : ""
          }`}
        />
      </button>

      {isOpen && (
        <div
          className={`absolute left-0 flex flex-col min-w-full z-10 ${optDivClassName} transition-opacity duration-500 ease-out opacity-0 animate-fade-in`}
          style={{ top: "calc(100% + 4px)" }}
        >
          {options.map((option, idx) => (
            <button
              key={idx}
              onClick={() => handleOptionClick(option)}
              className={`${value === option && "hidden"} ${optBtnClassName}`}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
