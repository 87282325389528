export default function FilesHeader({ title, matches, buttons }) {
  return (
    <div className={`flex justify-between items-center relative`}>
      <h3
        className={`font-500 text-18 leading-6 text-black ${
          title ? "block" : "hidden"
        }`}
      >
        {title}
      </h3>
      <p className={`text-16 text-gray-400 ${matches ? "block" : "hidden"}`}>
        <span className="text-black">{matches}</span> matches found
      </p>
      <div className="ml-auto">{buttons}</div>
    </div>
  )
}
