import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react"
import { useLocation, useParams } from "react-router"
import { Link, useSearchParams } from "react-router-dom"
import axios from "axios"
import unidecode from "unidecode"

import { API_URL } from "@/config"
import { useAppSelector } from "@/redux/hooks"
import { Left, Right } from "@/components"
import { htmlFile as fakeHtmlFile } from "@/mock/data/tempfile"
import DocumentContext, {
  SemanticMatch,
} from "@/components/left/DocumentContext"
import {
  ProvisionKeys,
  useGetDocumentMetadataQuery,
  useGetSemanticSearchQuery, useUpdateSemanticLabelsMutation
} from "@/api/resources";
import AppPage from "@/AppPage"
import { Icon } from "@/assets"
import {
  accumulateText,
  useParser,
} from "@/components/right/readOnlyEditor/DocumentParser"
import { getIntersection } from "@/utils/keywords"
import { toast } from "sonner";

const PROBA_THRESHOLD = 0.05

function isSameBin(a, b) {
  if (a >= 0.6 && b >= 0.6) return true
  if (a >= 0.25 && a < 0.6 && b >= 0.25 && b < 0.6) return true
  if (a < 0.25 && b < 0.25) return true
  return false
}

function sortFn(a, b) {
  // originally: (a, b) => b.value - a.value
  if (isSameBin(b.value, a.value)) {
    return a.index - b.index
  } else {
    return b.value - a.value
  }
}

export default function page() {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const { hash } = useLocation()

  const [keywords, setKeywords] = useState<string[]>([])
  const [matches, setMatches] = useState<string[]>([])
  const [activeMatch, setActiveMatch] = useState<number | null>(
    isNaN(hash.slice(1)) ? null : parseInt(hash.slice(1)),
  )
  const [semanticSearchClass, setSemanticSearchClass] = useState<string | null>(
    searchParams.get("provision"),
  )

  const accessToken = useAppSelector((state) => state.auth.accessToken)
  const [htmlFile, setHtmlFile] = useState<string | null>(null)

  const location = useLocation()
  const { data: documentMetadata, isLoading } = useGetDocumentMetadataQuery(id)

  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" })
  }, [location.pathname])

  useEffect(() => {
    if (id !== undefined) {
      // setHtmlFile()
      const options = {
        headers: {
          Accept: "text/html",
          Authorization: `Bearer ${accessToken}`,
        },
      }
      axios
        .get(`${API_URL}/documents/${id}`, options)
        .then((resp) => {
          if (resp.status == 200) {
            setHtmlFile(resp.data)
          } else {
            setHtmlFile(null)
          }
        })
        .catch((err) => {
          // TODO
          // setHtmlFile(fakeHtmlFile)
          throw err
        })
    } else {
      setHtmlFile(fakeHtmlFile)
    }
  }, [id])

  const setKeywordsAndClearMatches = useCallback(
    (newKeywords) => {
      // Called when keywords are updated to also clear matches
      setMatches([])
      setActiveMatch(null)
      setKeywords(newKeywords)
    },
    [setKeywords, setMatches],
  )

  const { data: semanticSearchData } = useGetSemanticSearchQuery(
    { document_id: id, class: null },
    { skip: !id },
  )
  const { searchableElements } = useParser({ htmlFile, keywords, activeMatch })

  const semanticMatchesHtml: SemanticMatch[] = useMemo(() => {
    if (
      !semanticSearchData ||
      !searchableElements ||
      !semanticSearchClass ||
      documentMetadata?.file_type === "pdf"
    ) {
      return []
    }
    const data = semanticSearchData["predictions"][semanticSearchClass]
    if (data.length !== searchableElements.length) {
      console.log(
        `ERROR: data.length != searchableElements.length (${data.length}, ${searchableElements.length})`,
      )
      return []
    }
    const matches_ = []
    for (let i = 0; i < data.length; i++) {
      if (data[i] < PROBA_THRESHOLD) {
        continue
      }
      const elem = searchableElements[i]
      matches_.push({
        index: elem.idx,
        matchIndex: i,
        text: elem.text,
        value: data[i],
      })
    }
    return matches_.sort(sortFn)
  }, [semanticSearchData, searchableElements, semanticSearchClass])

  const semanticMatchesPdf: SemanticMatch[] = useMemo(() => {
    if (
      !semanticSearchData ||
      !semanticSearchClass ||
      documentMetadata?.file_type !== "pdf"
    ) {
      return []
    }

    // TODO: crashes if "document is being processed"
    const { text_chunks, provisions } = semanticSearchData
    if (!provisions || !text_chunks) return []

    const provision = provisions[semanticSearchClass as ProvisionKeys]
    if (!provision) return []

    const { predictions, user_labels } = provision

    const matches_ = []
    for (let i = 0; i < predictions.length; i++) {
      if (predictions[i] || user_labels[i]) {
        matches_.push({
          index: i,
          matchIndex: i,
          text: text_chunks[i]["text"],
          value: predictions[i],
          isUserValidated: user_labels[i],
        })
      }
    }
    return matches_
  }, [semanticSearchData, semanticSearchClass])

  // TODO: refactor the following ...

  const keywordsPrepro = useMemo(() => {
    return keywords
      .map((keyword) => {
        return keyword.replaceAll(/\s+/g, " ").trim().toLowerCase()
      })
      .filter((x) => x.length > 0)
  }, [keywords])

  const keywordMatchesPdf = useMemo(() => {
    if (
      !semanticSearchData ||
      !keywords ||
      documentMetadata?.file_type !== "pdf"
    ) {
      return []
    }

    const texts = semanticSearchData["text_chunks"]
    if (!texts) return []

    const matches_ = []
    for (let i = 0; i < texts.length; i++) {
      const text = texts[i]["text"]

      const { hasIntersection } = getIntersection(text, keywordsPrepro)

      if (hasIntersection) {
        // TODO: think about what index is which
        matches_.push({
          index: i,
          matchIndex: matches_.length,
          text,
          value: -1,
        })
      }
    }
    return matches_.sort(sortFn)
  }, [keywords, semanticSearchData])

  const allMatches = useMemo(() => {
    return semanticSearchData &&
      semanticSearchData.provisions &&
      semanticSearchData.text_chunks &&
      semanticSearchClass &&
      semanticSearchData.provisions[semanticSearchClass as ProvisionKeys]
      ? semanticSearchData?.text_chunks?.map((x, i) => ({
          index: i,
          matchIndex: i,
          text: x.text,
          value:
            semanticSearchData.provisions![
              semanticSearchClass as ProvisionKeys
            ]!.predictions[i],
          isUserValidated:
            semanticSearchData.provisions![
              semanticSearchClass as ProvisionKeys
            ]!.user_labels[i],
        }))
      : []
  }, [semanticSearchData, semanticSearchClass])

  const semanticMatches =
    documentMetadata?.file_type === "pdf"
      ? semanticSearchClass
        ? semanticMatchesPdf
        : keywordMatchesPdf
      : semanticMatchesHtml

  if (!isLoading && (!id || !documentMetadata)) {
    return (
      <AppPage>
        <div className="flex flex-col flex-grow gap-5 justify-center items-center">
          <span className="text-20">Document not found.</span>
          <Link
            to="/"
            className="flex items-center gap-2 font-500 text-15 text-gray-400 hover:text-gray-700"
          >
            <Icon name="BackArrow" />
            Back To Main Page
          </Link>
        </div>
      </AppPage>
    )
  }

  // local copy of allMatches with user changes
  const [bufferMatches, setBufferMatches] = useState<typeof allMatches>([])

  // FIXME: handle edge cases such as reloading
  useEffect(() => {
    // We need to keep track of allMatches changes, so we can't just pass this
    // as a parameter to the useState
    setBufferMatches(allMatches.map((match) => ({ ...match })))

    if (allMatches.length === 0 && semanticSearchClass) {
      // Not sure if we need to keep this; however, for presentation purposes I'll put it up here.
      toast.error(
        `Semantic matches not yet processed for "${semanticSearchClass.replaceAll("_", " ").toWellFormed()}."`,
        {
          description:
            "Please wait for the semantic search to finish processing.",
        },
      )
    }
  }, [allMatches])

  const setMatchUserLabel = useCallback((matchIndex: number, binaryLabel: boolean) => {
    const newBufferMatches = bufferMatches.slice()
    newBufferMatches[matchIndex].isUserValidated = binaryLabel ? 1 : 0
    setBufferMatches(newBufferMatches)
  }, [bufferMatches, setBufferMatches])

  const areMatchesModified =
    allMatches.length !== 0 &&
    bufferMatches.some(
      (match) =>
        match.isUserValidated !== allMatches[match.index].isUserValidated,
    )

  const [updateLabels] = useUpdateSemanticLabelsMutation()

  const persistUserLabels = async () => {
    const toastLoadingid = toast.loading("Updating labels...")

    const result = await updateLabels({
      document_id: id,
      target_class: semanticSearchClass ?? "",
      changed_labels: bufferMatches
        .map((match, i) =>
          match.isUserValidated !== allMatches[i].isUserValidated
            ? match.index
            : null,
        )
        .filter((x) => x !== null),
    })

    if (result.error) {
      toast.error("Error updating labels", {
        description: (result.error as any).data.detail,
        id: toastLoadingid,
      })
    } else {
      toast.success("Labels were successfully updated.", {
        id: toastLoadingid,
      })
    }
  }

  return (
    <DocumentContext.Provider
      value={{
        documentId: id,
        document: documentMetadata,
        htmlFile,
        semanticMatches,
        semanticSearchClass,
        setSemanticSearchClass,
        activeMatch,
        setActiveMatch,
        semanticSearchData,
        allMatches: bufferMatches,
        setMatchUserLabel,
        areMatchesModified,
        persistUserLabels,
      }}
    >
      <div className="flex w-full h-full overflow-hidden justify-between">
        <Left
          keywords={keywords}
          setKeywords={setKeywordsAndClearMatches}
          matches={
            documentMetadata?.file_type === "pdf" && !semanticSearchClass
              ? keywordMatchesPdf.map((m) => m.text)
              : matches
          }
          activeMatch={activeMatch}
          setActiveMatch={setActiveMatch}
        />
        <Right
          keywords={keywords}
          setMatches={setMatches}
          activeMatch={activeMatch}
        />
      </div>
    </DocumentContext.Provider>
  )
}
